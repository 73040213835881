import React from 'react';
import CustomDropdown from '../../../CustomDropdown/CustomDropdown';
import styles from './SelectField.module.css';

const SelectField = ({ field, value, onChange, error, style = {} }) => {
    return (
        <div className={styles.fieldContainer} style={style.container}>
            <label htmlFor={field.id} className={styles.label} style={style.label}>
                {field.label}
            </label>
            <CustomDropdown
                items={field.options.map(({ value, label }) => ({ value, label }))}
                value={value}
                onChange={onChange}
                multiSelect={field.multiSelect || false}
                style={{
                    height: '34px',
                    boxSizing: 'border-box',
                    ...style.select,
                }}
            />
{error && <p className={styles.error}>{error}</p>}

        </div>
    );
};

export default SelectField;
