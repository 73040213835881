/**
 * Transforms incomingNodeData JSON structure into the required nodeData format?.
 * @param {Array} incomingData - The incoming node data to be transformed.
 * @returns {Object} Transformed node data.
 */
export const transformNodeData = (incomingData) => {
  const transformedData = {};

  incomingData?.forEach((category) => {
    const groupLabel = category?.category_name;
    const groupIcon = category?.icon;

    const nodes = category?.tasks?.map((task) => ({
      id: task?.name?.toLowerCase()?.replace(/\s+/g, "-"),
      data: {
        configurations: task?.config_schema,
        properties: {
          label: task?.name,
          icon: task?.icon,
          styles: {
            background: task?.icon_background,
            borderRadius: getDynamicBorderRadius(task?.icon_background),
          },
        },
      },
    }));

    transformedData[
      category?.category_name?.toLowerCase()?.replace(/\s+/g, "")
    ] = {
      groupLabel: groupLabel,
      groupIcon: groupIcon,
      nodes: nodes,
    };
  });

  return transformedData;
};

/**
 * Returns a dynamic border radius based on the background color?.
 * @param {string} backgroundColor - The background color for the task?.
 * @returns {string} Border radius value?.
 */
const getDynamicBorderRadius = (backgroundColor) => {
  return backgroundColor ? "15px" : "10px";
};
