// reducers/breadcrumbReducer.js

import { UPDATE_BREADCRUMBS } from '../actions/actions';

const initialState = {
    breadcrumbs: []
};

const breadcrumbReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_BREADCRUMBS:
            return {
                ...state,
                breadcrumbs: action.payload
            };
        default:
            return state;
    }
};

export default breadcrumbReducer;
