import { createTheme } from '@mui/material/styles';
import '@fontsource/inter'; // Ensure the font is imported

const theme = createTheme({
  typography: {
    fontFamily: 'Inter, Arial, sans-serif',
    heading: {
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '24.2px',
      letterSpacing: '0.01em',
    },
    suheadding:{
      fonSize: 20,
      fontWeight: 600,
      lineHeight: 24.,
      letterSpacing: '0.01em',
      textAlign: 'left',
      color:'#000000'
    },
    h1: {
      fontSize: '2rem',
      fontWeight: 700,
    },
    h2: {
      fontSize: '1.75rem',
      fontWeight: 700,
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: 700,
    },
    h4: {
      fontSize: '1.25rem',
      fontWeight: 700,
    },
    h5: {
      fontSize: '1rem',
      fontWeight: 700,
    },
    h6: {
      fontSize: '0.875rem',
      fontWeight: 700,
    },
    body1: {
      fontSize: '1rem',
    },
    body2: {
      fontSize: '0.875rem',
    },
    tableCellHeader: {
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '16.94px',
      letterSpacing: '0.01em',
      color:'#949494'
    },
    tableCellData: {
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '16.94px',
      letterSpacing: '0.01em',
      color:'#000000'
    },

  },
  palette: {
    primary: {
      main:'#4F63BE',
    },
    secondary: {
      main: '#7398C7', // Example secondary color
    },
    text: {
      primary: '#FFFFFF', // Example text color
      secondary: '#000000',
    },
    sidebar:{
      normal:'#6B6B6B',
      selected:'#4F63BE'
    },
    background: {
      default: '#F5F9FF', // Example background color
      paper: '#FFFFFF', // Background color for paper components
    },

    // --------------------- done--------------------- ///
    success:{
      main: '#62E4B5',
    },
    inprogress:{
      main: '#E4C554'
    },
    warning:{
      main: '#FF8F51'

    },
    error:{
      main: '#EF6A6A'

    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          padding: '8px 16px',
          textTransform: 'none',
          fontWeight: 600,
        },
        contained: {
          backgroundColor: '#4F63BE',
          color: '#FFFFFF',
          '&:hover': {
            backgroundColor: '#3e50b4',
          },
        },
        outlined: {
          borderColor: '#4F63BE',
          color: '#4F63BE',
          '&:hover': {
            borderColor: '#3e50b4',
            color: '#3e50b4',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
          boxShadow: 'none',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '16px',
        },
      },
    },
  },
});

export default theme;