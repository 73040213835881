import React, { useEffect, useState, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import NotificationsHeader from "../components/NotificationsHeader/NotificationsHeader";
import NotificationsSidebar from "../components/NotificationsSidebar/NotificationsSidebar";
import NotificationContent from "../components/NotificationContent/NotificationContent";
import ConfirmationDialog from "../../../components/reusableComponents/ConfirmationDialog/ConfirmationDialog";
import { useNotifications } from "../../../hooks/useNotifications";
import styles from "./Inbox.module.css";

const Inbox = () => {
  const { notificationId } = useParams();
  const navigate = useNavigate();
  const accessToken = useSelector((state) => state.auth.accessToken);
  const notifications = useSelector(state => state?.notifications?.notifications ?? []);
  const [currentNotification, setCurrentNotification] = useState(null);
  
  // Delete confirmation states
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  
  const { 
    loading, 
    error,
    markAsRead,
    markAsUnread,
    deleteNotifications,
  } = useNotifications(accessToken);

  const updateCurrentNotification = useCallback((notification) => {
    setCurrentNotification(notification);
    navigate(`/user-inbox/${notification.id}`, { replace: true });
  }, [navigate]);

  useEffect(() => {
    if (!notifications.length) {
      setCurrentNotification(null);
      navigate('/user-inbox', { replace: true });
      return;
    }

    const selectedNotification = notificationId 
      ? notifications.find(n => n.id === parseInt(notificationId, 10))
      : notifications[0];

    if (selectedNotification) {
      updateCurrentNotification(selectedNotification);
    } else {
      navigate('/user-inbox', { replace: true });
    }
  }, [notifications, notificationId, updateCurrentNotification, navigate]);

  const handleNotificationClick = useCallback(async (notification) => {
    updateCurrentNotification(notification);
    if (!notification.isRead) {
      await markAsRead([notification.id]);
    }
  }, [updateCurrentNotification, markAsRead]);

  // Delete handling with confirmation
  const handleDeleteClick = useCallback((ids) => {
    setDeleteTarget(ids);
    setIsDeleteDialogOpen(true);
  }, []);

  const handleDelete = useCallback(async () => {
    if (!deleteTarget) return;

    const notificationIds = Array.isArray(deleteTarget) ? deleteTarget : [deleteTarget];
    setIsDeleting(true);

    try {
      await deleteNotifications(notificationIds);

      if (!currentNotification?.id) return;

      if (notificationIds.includes(currentNotification.id)) {
        const nextNotification = notifications.find(n => !notificationIds.includes(n.id));
        if (nextNotification) {
          await handleNotificationClick(nextNotification);
        } else {
          setCurrentNotification(null);
          navigate('/user-inbox', { replace: true });
        }
      }
    } catch (error) {
      console.error('Failed to delete notifications:', error);
      // You could add toast notification here for error
    } finally {
      setIsDeleting(false);
      setIsDeleteDialogOpen(false);
      setDeleteTarget(null);
    }
  }, [deleteTarget, currentNotification, notifications, deleteNotifications, handleNotificationClick, navigate]);

  const handleCloseDeleteDialog = useCallback(() => {
    if (isDeleting) return; // Prevent closing while deleting
    setIsDeleteDialogOpen(false);
    setDeleteTarget(null);
  }, [isDeleting]);

  const handleMarkAsRead = useCallback(async (ids) => {
    await markAsRead(Array.isArray(ids) ? ids : [ids]);
  }, [markAsRead]);

  const handleMarkAsUnread = useCallback(async (ids) => {
    await markAsUnread(Array.isArray(ids) ? ids : [ids]);
  }, [markAsUnread]);

  const getDeleteDialogMessage = useCallback(() => {
    if (!deleteTarget) return '';
    
    const count = Array.isArray(deleteTarget) ? deleteTarget.length : 1;
    if (count === 1) {
      const notificationId = Array.isArray(deleteTarget) ? deleteTarget[0] : deleteTarget;
      const notification = notifications.find(n => n.id === notificationId);
      return `Are you sure you want to delete the notification "${notification?.title || ''}"? This action cannot be undone.`;
    }
    return `Are you sure you want to delete ${count} notifications? This action cannot be undone.`;
  }, [deleteTarget, notifications]);

  if (error) {
    return <div className={styles.error}>{error}</div>;
  }

  const renderConfirmationDialog = () => (
    <ConfirmationDialog
      isOpen={isDeleteDialogOpen}
      onClose={handleCloseDeleteDialog}
      onConfirm={handleDelete}
      variant="danger"
      title={Array.isArray(deleteTarget) && deleteTarget?.length > 1 
        ? "Delete Notifications" 
        : "Delete Notification"}
      message={getDeleteDialogMessage()}
      confirmText={isDeleting ? "Deleting..." : "Delete"}
      cancelText="Cancel"
      showIcon={true}
      position="center"
      size="small"
      closeOnOverlayClick={!isDeleting}
      closeOnEscape={!isDeleting}
      blurBackground={true}
      isConfirmLoading={isDeleting}
      isConfirmDisabled={isDeleting}
      className={styles.deleteDialog}
    />
  );

  const renderContent = () => {
    if (!notifications.length) {
      return (
        <NotificationContent
          notification={null}
          totalNotifications={0}
        />
      );
    }

    return (
      <>
        <NotificationsSidebar
          notifications={notifications}
          loading={loading}
          selectedNotification={currentNotification}
          onNotificationClick={handleNotificationClick}
          onDelete={handleDeleteClick}
          onMarkAsRead={handleMarkAsRead}
          onMarkAsUnread={handleMarkAsUnread}
        />
        <NotificationContent
          notification={currentNotification}
          onDelete={currentNotification && (() => handleDeleteClick(currentNotification.id))}
          onMarkAsRead={currentNotification && (() => handleMarkAsRead(currentNotification.id))}
          onMarkAsUnread={currentNotification && (() => handleMarkAsUnread(currentNotification.id))}
          totalNotifications={notifications.length}
        />
        {renderConfirmationDialog()}
      </>
    );
  };

  return (
    <div className={styles.pageContainer}>
      <div className={styles.inboxContainer}>
        <NotificationsHeader />
        <div className={styles.mainContent}>
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default Inbox;