import React, { createContext, useState, useContext, useEffect } from 'react';
import { isTokenValid } from '../utils/tokenUtils';


export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  useEffect(() => {
    // Check for token in localStorage
    const token = localStorage.getItem('accessToken');
    
    // Validate the token
    if (token && isTokenValid(token)) {
      setIsAuthenticated(true);
      console.log("Token is valid, user is authenticated.");
    } else {
      console.log("No valid token found, user is not authenticated.");
    }
  }, []);

  const login = (token) => {
    // Set token in localStorage if you want to persist it across refreshes
    localStorage.setItem('accessToken', token);
    setIsAuthenticated(true); // Update the authentication state
  };

  const logout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('accessToken'); // Clear token on logout
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

