import React from 'react';
import { getValidationAttributes } from '../../helpers/validationHelpers';
import styles from './TextareaField.module.css';

const TextareaField = ({ field, value, onChange, error, style = {} }) => {
    const validationAttributes = getValidationAttributes(field);

    return (
        <div className={styles.fieldContainer} style={style.container}>
            <label htmlFor={field.id} className={styles.label} style={style.label}>{field.label}</label>
            <textarea
                id={field.id}
                placeholder={field.placeholder}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                className={styles.textarea}
                style={style.textarea}
                {...validationAttributes}
            />
            {error && <p className={styles.error}>{error}</p>}
        </div>
    );
};

export default TextareaField;
