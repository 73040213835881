import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import styles from "./WorkflowHeader.module.css";
import backArrowRounded from "../../../../assetts/images/leftRoundedArrow.svg";
import searchIcon from "../../../../assetts/images/searchIcon.svg";
import { generateBreadcrumbElements } from "../../../../utils/breadcrumbUtils"; // Import the utility function

const WorkflowHeader = () => {
  const navigate = useNavigate();
  const breadcrumbs = useSelector((state) => state.breadcrumbs.breadcrumbs);

  const handleBackButtonClick = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <div className={styles.headerContainer}>
      <div className={styles.titleSection}>
        <h1 className={styles.title}>Workflow</h1>
        <div className={styles.breadcrumb}>
          {generateBreadcrumbElements(breadcrumbs, navigate)}{" "}
          {/* Use the utility function */}
        </div>
      </div>
      <div className={styles.searchSection}>
        <button className={styles.backButton} onClick={handleBackButtonClick}>
          <img src={backArrowRounded} alt="Back" className={styles.backIcon} />
        </button>
        <div className={styles.searchWrapper}>
          <img src={searchIcon} alt="Search" className={styles.searchIcon} />
          <input
            type="text"
            className={styles.searchInput}
            placeholder="Search..."
          />
        </div>
      </div>
    </div>
  );
};

export default WorkflowHeader;
