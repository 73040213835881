// actions/authActions.js


export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';


export const STORE_TOKENS = 'STORE_TOKENS'; // Action to store tokens
export const CLEAR_TOKENS = 'CLEAR_TOKENS'; // For clearing tokens on logout


export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE ='LOGOUT_FAILURE';

export const REFRESH_TOKEN_REQUEST = 'REFRESH_TOKEN_REQUEST';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILURE = 'REFRESH_TOKEN_FAILURE';


export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';
export const CLEAR_SIGNUP_MESSAGE = 'CLEAR_SIGNUP_MESSAGE';
export const STORE_USER_DETAILS = 'STORE_USER_DETAILS';

export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const LOGOUT_USER = 'LOGOUT_USER';
export const REAUTHENTICATE_REQUEST = 'REAUTHENTICATE_REQUEST';
export const REAUTHENTICATE_SUCCESS = 'REAUTHENTICATE_SUCCESS';
export const REAUTHENTICATE_FAILED = 'REAUTHENTICATE_FAILED';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const SETNEW_PASSWORD_REQUEST = 'SETNEW_PASSWORD_REQUEST';
export const SETNEW_PASSWORD_SUCCESS = 'SETNEW_PASSWORD_SUCCESS';
export const SETNEW_PASSWORD_FAILURE = 'SETNEW_PASSWORD_FAILURE';

export const GOOGLE_LOGIN_REQUEST = 'GOOGLE_LOGIN_REQUEST';
export const GOOGLE_LOGIN_SUCCESS ='GOOGLE_LOGIN_SUCCESS';
export const GOOGLE_LOGIN_FAILURE = 'GOOGLE_LOGIN_FAILURE';
export const GOOGLE_LOGIN_CALLBACK = 'GOOGLE_LOGIN_CALLBACK';

export const LINKEDIN_LOGIN_REQUEST = 'LINKEDIN_LOGIN_REQUEST';
export const LINKEDIN_LOGIN_SUCCESS = 'LINKEDIN_LOGIN_SUCCESS';
export const LINKEDIN_LOGIN_FAILURE = 'LINKEDIN_LOGIN_FAILURE';
export const LINKEDIN_LOGIN_CALLBACK = 'LINKEDIN_LOGIN_CALLBACK';

export const MICROSOFT_LOGIN_REQUEST = 'MICROSOFT_LOGIN_REQUEST';
export const MICROSOFT_LOGIN_SUCCESS = 'MICROSOFT_LOGIN_SUCCESS';
export const MICROSOFT_LOGIN_FAILURE = 'MICROSOFT_LOGIN_FAILURE';
export const MICROSOFT_LOGIN_CALLBACK = 'MICROSOFT_LOGIN_CALLBACK';

export const SOCIAL_MEDIA_LOGIN_REQUEST = 'SOCIAL_MEDIA_LOGIN_REQUEST';
export const SOCIAL_MEDIA_LOGIN_SUCCESS = 'SOCIAL_MEDIA_LOGIN_SUCCESS';
export const SOCIAL_MEDIA_LOGIN_FAILURE = 'SOCIAL_MEDIA_LOGIN_FAILURE';
export const SOCIAL_MEDIA_LOGIN_CALLBACK = 'SOCIAL_MEDIA_LOGIN_CALLBACK';

// Action creators
export const loginRequest = (credentials) => {
    console.log("auth Actions LOGIN ",credentials )
    return{
        type: LOGIN_REQUEST,
        payload: credentials,
    }
};


export const loginSuccess = (tokens) => ({
    type: LOGIN_SUCCESS,
    payload: tokens,
});


export const loginFailure = (error) => ({
    type: LOGIN_FAILURE,
    payload: error,
});


export const logoutRequest = () => ({
    type: LOGOUT_REQUEST,
});
export const logoutSuccess = () => ({
    type: LOGOUT_SUCCESS,
});
export const logoutFailure = (error) => ({ 
    type: LOGOUT_FAILURE, 
    payload: error 
});

export const refreshTokenRequest = () => ({
    type: REFRESH_TOKEN_REQUEST,
});

export const refreshTokenSuccess = (tokens) => ({
    type: REFRESH_TOKEN_SUCCESS,
    payload: tokens,
});


export const refreshTokenFailure = () => ({
    type: REFRESH_TOKEN_FAILURE,
});

// for Registration  Action Creators
export const registerRequest = (userData) => ({
    type: REGISTER_REQUEST,
    payload: userData,
});


export const registerSuccess = (message) => ({
    type: REGISTER_SUCCESS,
    payload: message,
});


export const registerFailure = (error) => ({
    type: REGISTER_FAILURE,
    payload: error,
});
export const clearSignupMessage =() =>{
   return{
        type:CLEAR_SIGNUP_MESSAGE
   }
}
export const storeTokens = (accessToken, refreshToken) => ({
    type: STORE_TOKENS,
    payload: { accessToken, refreshToken },
});
export const clearTokens = () => {
    return {
      type: CLEAR_TOKENS,
    };
  };
export const storeUserDetails = (userDetails) => ({
    type: STORE_USER_DETAILS,
    payload: userDetails,
});

// Action to set the access token
export const setAccessToken = (token) => ({
    type: SET_ACCESS_TOKEN,
    payload: token,
});

// Action Creators
export const reAuthenticationRequest = (token) => ({
    type: REAUTHENTICATE_REQUEST,
    payload: token,
});
export const reAuthenticateSuccess = (userDetails) => {
    console.log("re-auth Actions Success " )
    return{
        type: REAUTHENTICATE_SUCCESS,
        payload: userDetails,
    }
  };

export const sendPasswordLinkRequest = (email) => ({
    type: RESET_PASSWORD_REQUEST,
    payload: { email },
});
  
export const resetPasswordSuccess = (message) => ({
    type: RESET_PASSWORD_SUCCESS,
    payload: { message },
});

export const resetPasswordFailure = (error) => ({
    type: RESET_PASSWORD_FAILURE,
    payload: { error },
});

export const setNewPasswordRequest = (userData) => ({
    type: SETNEW_PASSWORD_REQUEST,
    payload: userData,
});
export const setNewPasswordSuccess = (message) => ({
    type: SETNEW_PASSWORD_SUCCESS,
    payload: { message },
});

export const setNewPasswordFailure = (error) => ({
    type: SETNEW_PASSWORD_FAILURE,
    payload: { error },
});

export const googleLoginRequest = () => ({
    type: GOOGLE_LOGIN_REQUEST,
  });
  
export const googleLoginSuccess = (userData) => ({
    type: GOOGLE_LOGIN_SUCCESS,
    payload: userData,
});

export const googleLoginFailure = (error) => ({
    type: GOOGLE_LOGIN_FAILURE,
    payload: error,
});

export const googleLoginCallback = (code) => ({
    type: GOOGLE_LOGIN_CALLBACK,
    payload: code,
  });

export const linkedInLoginRequest = () => ({
    type: LINKEDIN_LOGIN_REQUEST,
});
  
export const linkedInLoginSuccess = (userData) => ({
    type: LINKEDIN_LOGIN_SUCCESS,
    payload: userData,
});

export const linkedInLoginFailure = (error) => ({
    type: LINKEDIN_LOGIN_FAILURE,
    payload: error,
});

export const linkedInLoginCallback = (code) => ({
    type: LINKEDIN_LOGIN_CALLBACK,
    payload: code,
  });
export const MicrosoftLoginRequest = () => ({
    type: MICROSOFT_LOGIN_REQUEST,
});
  
export const MicrosoftLoginSuccess = (userData) => ({
    type: MICROSOFT_LOGIN_SUCCESS,
    payload: userData,
});

export const MicrosoftLoginFailure = (error) => ({
    type: MICROSOFT_LOGIN_FAILURE,
    payload: error,
});

export const MicrosoftLoginCallback = (code) => ({
    type: MICROSOFT_LOGIN_CALLBACK,
    payload: code,
});

export const socialMediaLoginRequest = (userData) => ({
    type: SOCIAL_MEDIA_LOGIN_REQUEST,
    payload: userData,
});
  
export const socialMediaLoginSuccess = (userData) => ({
    type: SOCIAL_MEDIA_LOGIN_SUCCESS,
    payload: userData,
});

export const socialMediaLoginFailure = (error) => ({
    type: SOCIAL_MEDIA_LOGIN_FAILURE,
    payload: error,
});

export const socialMediaLoginCallback = (userData) => ({
    type: SOCIAL_MEDIA_LOGIN_CALLBACK,
    payload: userData,
});


// export const reAuthenticateSuccess = (userDetails) => {
//     console.log("re auth Actions Success " )
//     return{
//         type: REAUTHENTICATE_SUCCESS,
//         payload: userDetails,
//     }
//   };