import React, { useState, useCallback } from 'react';
import { useSelector } from "react-redux";
import { Toolbar, Box, IconButton, Badge, AppBar } from '@mui/material';
import CircleNotificationsOutlinedIcon from '@mui/icons-material/CircleNotificationsOutlined';
import logo from '../assetts/images/RUNMYBOT_logo2.svg';
import NotificationsDropdown from './notifications/NotificationsDropdown';
import { useNotifications } from '../hooks/useNotifications';
import {  useNavigate } from 'react-router-dom';

const Navbar = ({ toggleSidebar }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const accessToken = useSelector((state) => state.auth.accessToken);
  const notifications = useSelector(state => state?.notifications?.notifications ?? []);
  const navigate = useNavigate();
  const { 
    loading,
    markAsRead,
    markAsUnread,
    deleteNotifications,
    refreshNotifications 
  } = useNotifications(accessToken);

  const toggleDropdown = useCallback(() => {
    if (!dropdownVisible) {
      refreshNotifications();
    }
    setDropdownVisible(!dropdownVisible);
  }, [dropdownVisible, refreshNotifications]);
  const goHome = (()=>{
    navigate('/dashboard',{ state: { view:'Activities' } });
  })
  const unreadNotifications = notifications.filter(notification => !notification.readAt);
  const unreadNotificationsCount = unreadNotifications.length;

  return (
    <AppBar position="fixed" style={styles.topbar}>
      <Toolbar sx={{ marginTop: '5px' }}>
        {/* <img src={logo} alt="Logo" style={{ height: '60%', marginRight: 16 }} /> */}
        <div onClick={() =>  goHome()} style={{ cursor: 'pointer', display: 'inline-block' }}>
          <img src={logo} alt="Logo" style={{ height: '60%', marginRight: 16 }} />
        </div>
        <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
          <IconButton sx={{ color: '#B3B3B3' }} onClick={toggleDropdown}>
            <Badge 
              badgeContent={loading ? 0 : unreadNotificationsCount} 
              color="primary"
            >
              <CircleNotificationsOutlinedIcon sx={{ fontSize: 35 }} />
            </Badge>
          </IconButton>
        </Box>
      </Toolbar>
      {dropdownVisible && (
        <NotificationsDropdown 
          notifications={unreadNotifications}
          loading={loading}
          markAsRead={markAsRead}
          markAsUnread={markAsUnread}
          deleteNotifications={deleteNotifications}
        />
      )}
    </AppBar>
  );
};

const styles = {
  topbar: {
    height: '72px',
    backgroundColor: '#F5F9FF',
    zIndex: 1000,
    borderBottom: '1px solid #D0D0D0',
    boxShadow: '0px 0px 0px 0px #00000014',
  },
};

export default Navbar;