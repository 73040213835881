import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Box, Typography, Grid } from '@mui/material';
import logo from '../../assetts/images/RUNMYBOT_logo.svg';
import sideImage from '../../assetts/images/forgotpassword.svg';
import cornerImage from '../../assetts/images/Corner_image.svg';
import '../../pages/Login.css';
import { useDispatch } from 'react-redux';
import { sendPasswordLinkRequest } from '../../redux/actions/authActions';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');
  const dispatch = useDispatch();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Basic validation for email
    if (!email) {
      setError('Email is required');
      return;
    }

    setError('');
    console.log('Email:', email);

    // Dispatch the action to send the password reset link
    dispatch(sendPasswordLinkRequest(email));

    // Set submitted to true to show the success message and resend button
    setSubmitted(true);
  };

  const handleResend = () => {
    setSubmitted(false); // Reset submitted state to trigger form again
    handleSubmit(new Event('submit')); // Resend the link by calling handleSubmit
  };

  return (
    <Grid container style={{ height: '100vh', backgroundColor: '#FFFFFF' }} spacing={0}>
      {/* Login Form */}
      <Grid item xs={12} md={6} container direction="column" justifyContent="center" alignItems="center" spacing={0} padding={10}>
        <Box sx={{ maxWidth: 500, textAlign: 'center', marginLeft: '70px' }}> {/* Adjusted marginLeft */}
          {/* Image on Top Left */}
          <Box sx={{ position: 'absolute', top: 0, left: 0 }}>
            <img src={cornerImage} alt="Corner" style={{ width: '100px', height: 'auto' }} />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', top: -10 }}>
            <img src={logo} alt="Logo" style={{ width: '279.57px', marginBottom: '20px' }} />
          </Box>
          <Typography variant="body2" className="text-style">
            Please enter your registered email address. An email notification with a reset link will then be sent to you.
          </Typography>
          <TextField
            label="Email"
            onChange={handleEmailChange}
            variant="outlined"
            fullWidth
            required
            margin="normal"
            className='customTextField'
          />

          {!submitted ? (
            <Button variant="contained" className='loginbutton' fullWidth onClick={handleSubmit} sx={{ mt: 2, boxShadow: 'none' }}>
              Send Reset Link
            </Button>
          ) : (
            <Box>
              <Typography variant="body2" style={{ color: 'green', marginTop: '16px' }}>
                A reset link has been sent to your email. Please check your inbox.
              </Typography>
              <Button variant="outlined" fullWidth onClick={handleResend} sx={{ mt: 2 }}>
                Resend Link
              </Button>
            </Box>
          )}

          <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'space-between', mt: 2, mb: 6 }}>
            <Typography variant="body2" className="text-style">
              <a href="/login" style={{ textDecoration: 'none', color: '#E27DDD' }}>
                Login
              </a>
            </Typography>
          </Box>
        </Box>
      </Grid>

      {/* Side Image */}
      <Grid
        item
        xs={12}
        md={5}
        container
        justifyContent="center"  // Center horizontally
        alignItems="center"      // Center vertically
        style={{ backgroundColor: '#ffffff', height: '100vh' }}
      >
        <img
          src={sideImage}
          alt="Side"
          style={{
            width: '90%',
            height: 'auto',
            maxWidth: '90vw',
            maxHeight: '90vh',
            objectFit: 'contain'
          }}
        />
      </Grid>
    </Grid>
  );
};

export default ForgotPassword;
