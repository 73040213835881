import React, { useState } from "react";
import styles from "./MappingAndTransformationTabs.module.css";
import MappingForm from "./MappingForm";
import TransformationForm from "./TransformationForm";

const MappingAndTransformationTabs = ({
  node,
  connectedSources,
  connectedTargets,
  mappings,
  setMappings,
  transformations,
  setTransformations,
}) => {
  const [activeTab, setActiveTab] = useState("input-mapping");

  // Handle mapping change
  const handleMappingChange = (type, key, value) => {
    setMappings((prevMappings) => ({
      ...prevMappings,
      [type]: {
        ...prevMappings[type],
        [key]: value,
      },
    }));
  };

  // Handle transformation change
  const handleTransformationChange = (type, key, value) => {
    setTransformations((prevTransformations) => ({
      ...prevTransformations,
      [type]: {
        ...prevTransformations[type],
        [key]: value,
      },
    }));
  };

  return (
    <div className={styles.mappingTransformationTabs}>
      {/* Tab Header */}
      <div className={styles.tabHeader}>
        <button
          className={`${styles.tabButton} ${activeTab === "input-mapping" ? styles.activeTab : ""}`}
          onClick={() => setActiveTab("input-mapping")}
        >
          Input Mapping
        </button>
        <button
          className={`${styles.tabButton} ${activeTab === "output-mapping" ? styles.activeTab : ""}`}
          onClick={() => setActiveTab("output-mapping")}
        >
          Output Mapping
        </button>
        <button
          className={`${styles.tabButton} ${activeTab === "input-transformation" ? styles.activeTab : ""}`}
          onClick={() => setActiveTab("input-transformation")}
        >
          Input Transformation
        </button>
        <button
          className={`${styles.tabButton} ${activeTab === "output-transformation" ? styles.activeTab : ""}`}
          onClick={() => setActiveTab("output-transformation")}
        >
          Output Transformation
        </button>
      </div>

      {/* Tab Content */}
      <div className={styles.tabContent}>
        {activeTab === "input-mapping" && (
          <MappingForm
            isInputMapping={true}
            mappings={mappings}
            node={node}
            connectedSources={connectedSources}
            connectedTargets={connectedTargets}
            handleMappingChange={handleMappingChange}
          />
        )}
        {activeTab === "output-mapping" && (
          <MappingForm
            isInputMapping={false}
            mappings={mappings}
            node={node}
            connectedSources={connectedSources}
            connectedTargets={connectedTargets}
            handleMappingChange={handleMappingChange}
          />
        )}
        {activeTab === "input-transformation" && (
          <TransformationForm
            isInputTransformation={true}
            transformations={transformations}
            node={node}
            connectedSources={connectedSources}
            connectedTargets={connectedTargets}
            handleTransformationChange={handleTransformationChange}
          />
        )}
        {activeTab === "output-transformation" && (
          <TransformationForm
            isInputTransformation={false}
            transformations={transformations}
            node={node}
            connectedSources={connectedSources}
            connectedTargets={connectedTargets}
            handleTransformationChange={handleTransformationChange}
          />
        )}
      </div>
    </div>
  );
};

export default MappingAndTransformationTabs;
