import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from './Routes';
import AuthProvider from './contexts/AuthContext'
import { Provider, useDispatch} from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import store from './redux/store/store';
import ReAuth from './ReAuth';

function App() {

  return (
    <Provider store={store}>
      <ThemeProvider  theme={theme}>
      <AuthProvider>
        <ReAuth /> 
        <Router>
          <AppRoutes />
        </Router>
        </AuthProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;