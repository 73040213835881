import { ReactComponent as WarningIcon } from "../../../../assetts/images/warning.svg";
import { ReactComponent as SuccessIcon } from "../../../../assetts/images/success.svg";
import { ReactComponent as ErrorIcon } from "../../../../assetts/images/error.svg";
import { ReactComponent as PendingIcon } from "../../../../assetts/images/pending.svg";

// Define dropdown items for statuses
export const statusOptions = [
  { value: "All", label: "All" },
  { value: "Warning", label: "Warning" },
  { value: "Error", label: "Error" },
  { value: "Success", label: "Success" },
  { value: "Pending", label: "Pending" },
];

// Filter logs based on the selected status
export const filterLogs = (logs, selectedStatus) => {
  if (selectedStatus === "All") return logs;
  return logs.filter(
    (log) => log.status.toLowerCase() === selectedStatus.toLowerCase(),
  );
};

// Get the appropriate status icon based on the log status
export const getStatusIcon = (status, styles) => {
  switch (status.toLowerCase()) {
    case "warning":
      return <WarningIcon className={styles.statusIcon} />;
    case "success":
      return <SuccessIcon className={styles.statusIcon} />;
    case "error":
      return <ErrorIcon className={styles.statusIcon} />;
    case "pending":
      return <PendingIcon className={styles.statusIcon} />;
    default:
      return <p>*</p>; // Default fallback for undefined statuses
  }
};

// Get the appropriate styles based on the log status
export const getStatusStyles = (status) => {
  switch (status.toLowerCase()) {
    case "success":
      return { "--border-color": "#62E4B5" };
    case "error":
      return { "--border-color": "#EF6A6A" };
    case "pending":
      return { "--border-color": "#E4C554" };
    case "warning":
      return { "--border-color": "#E27DDD" };
    default:
      return { "--border-color": "#C4C4C5" };
  }
};
