import React, { useState, useEffect } from "react";
import { Tooltip } from "@mui/material";
import styles from "./FilterSidebar.module.css";
import { ReactComponent as SystemSettingsIcon } from "../../../../assetts/images/systemSettings.svg";
import { ReactComponent as Function } from "../../../../assetts/images/Function.svg";
import { ReactComponent as DropdownIconClosed } from "../../../../assetts/images/dropdownIconClosed.svg";
import { ReactComponent as DropdownIconOpen } from "../../../../assetts/images/dropdownIconOpen.svg";
import { ReactComponent as SearchIcon } from "../../../../assetts/images/searchIcon.svg";

const FilterSidebar = ({ systems, functions, onTagSelectionChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isFunctionsOpen, setIsFunctionsOpen] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedFunctions, setSelectedFunctions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleByFunctionsToggle = () => {
    setIsFunctionsOpen(!isFunctionsOpen);
  };

  const handleSystemCheckboxChange = (tagId) => {
    const newSelectedTags = selectedTags.includes(tagId)
      ? selectedTags.filter((id) => id !== tagId)
      : [...selectedTags, tagId];

    setSelectedTags(newSelectedTags);
    onTagSelectionChange(newSelectedTags, selectedFunctions); // Pass the selected tags and functions to the parent
  };

  const handleFunctionCheckboxChange = (func) => {
    const newSelectedFunctions = selectedFunctions.includes(func)
      ? selectedFunctions.filter((selectedFunc) => selectedFunc !== func)
      : [...selectedFunctions, func];

    setSelectedFunctions(newSelectedFunctions);
    onTagSelectionChange(selectedTags, newSelectedFunctions); // Pass the selected tags and functions to the parent
  };

  const filteredSystems = systems.filter(
    (filter) => filter.name && filter.name.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
  );

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className={styles.filterSidebar}>
      <div className={styles.searchContainer}>
        <SearchIcon className={styles.searchIcon} />
        <input
          type="text"
          placeholder="Search..."
          className={styles.searchInput}
          value={searchTerm}
          onChange={handleSearch}
        />
        <hr className={styles.dottedLine} />
      </div>

      {/* System Filter */}
      <div className={styles.menu}>
        <div className={styles.listHeader} onClick={handleToggle}>
          <div className={styles.listHeaderContent}>
            <SystemSettingsIcon className={styles.icon} />
            <span className={styles.sectionTitle}>By System</span>
          </div>
          {isOpen ? (
            <DropdownIconOpen className={styles.expandIcon} />
          ) : (
            <DropdownIconClosed className={styles.expandIcon} />
          )}
        </div>

        {isOpen && (
          <ul className={styles.filterList}>
            {filteredSystems.map((filter) => (
              <li key={filter.id} className={styles.listItem}>
                <input
                  type="checkbox"
                  checked={selectedTags.includes(filter.id)}
                  className={styles.checkbox}
                  onChange={() => handleSystemCheckboxChange(filter.id)}
                />
                <Tooltip title={filter.name} arrow>
                  <span className={styles.listItemText}>{filter.name}</span>
                </Tooltip>
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* Functions Filter */}
      <div className={styles.menu}>
        <div className={styles.listHeader} onClick={handleByFunctionsToggle}>
          <div className={styles.listHeaderContent}>
            <Function className={styles.icon} />
            <span className={styles.sectionTitle}>By Function</span>
          </div>
          {isFunctionsOpen ? (
            <DropdownIconOpen className={styles.expandIcon} />
          ) : (
            <DropdownIconClosed className={styles.expandIcon} />
          )}
        </div>

        {isFunctionsOpen && (
          <ul className={styles.filterList}>
            {functions.map((func, index) => (
              <li key={index} className={styles.listItem}>
                <input
                  type="checkbox"
                  checked={selectedFunctions.includes(func)}
                  className={styles.checkbox}
                  onChange={() => handleFunctionCheckboxChange(func)}
                />
                <Tooltip title={func} arrow>
                  <span className={styles.listItemText}>{func}</span>
                </Tooltip>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default FilterSidebar;
