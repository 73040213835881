import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { ChevronLeft, ChevronRight, Inbox, Loader } from "lucide-react";
import NotificationItem from "../../../../components/notifications/NotificationItem/NotificationItem";
import styles from "./NotificationsSidebarList.module.css";

const NotificationsSidebarList = ({
  paginatedNotifications,
  selectedNotifications,
  selectedNotification,
  onNotificationClick,
  onCheckboxChange,
  onMarkAsRead,
  onMarkAsUnread,
  onDelete,
  currentPage,
  setCurrentPage,
  hasMoreNotifications,
  listVariants,
  isLoading
}) => {
  // Sort notifications by timestamp (latest first)
  const sortedNotifications = React.useMemo(() => {
    return [...paginatedNotifications].sort((a, b) => {
      const dateA = new Date(a.timestamp);
      const dateB = new Date(b.timestamp);
      return dateB - dateA;
    });
  }, [paginatedNotifications]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        when: "beforeChildren"
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, x: -20, height: 0 },
    visible: { 
      opacity: 1, 
      x: 0, 
      height: "auto",
      transition: { type: "spring", stiffness: 300, damping: 30 }
    },
    exit: { 
      opacity: 0, 
      x: -20, 
      height: 0,
      transition: { duration: 0.2 }
    }
  };

  return (
    <motion.div 
      className={styles.notificationsListContainer}
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      {isLoading ? (
        <div className={styles.loadingState}>
          <Loader className={styles.loadingIcon} size={24} />
          <span>Loading notifications...</span>
        </div>
      ) : sortedNotifications.length === 0 ? (
        <div className={styles.emptyState}>
          <Inbox size={48} className={styles.emptyIcon} />
          <h3>No notifications</h3>
          <p>You're all caught up!</p>
        </div>
      ) : (
        <AnimatePresence mode="wait">
          <motion.div 
            className={styles.notificationsList}
            variants={containerVariants}
          >
            {sortedNotifications.map((notification, index) => (
              <motion.div
                key={notification.id}
                variants={itemVariants}
                layoutId={`notification-${notification.id}`}
                className={styles.notificationWrapper}
              >
                <NotificationItem
                  notification={notification}
                  type='inbox'
                  selectedNotification={selectedNotification}
                  isSelected={selectedNotifications.includes(notification.id)}
                  onNotificationClick={onNotificationClick}
                  onCheckboxChange={onCheckboxChange}
                  onToggleReadStatus={() =>
                    notification.isRead
                      ? onMarkAsUnread([notification.id])
                      : onMarkAsRead([notification.id])
                  }
                  onDelete={() => onDelete([notification.id])}
                />
              </motion.div>
            ))}
          </motion.div>
        </AnimatePresence>
      )}

      {(currentPage > 0 || hasMoreNotifications) && (
        <motion.div 
          className={styles.paginationContainer}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
        >
          <div className={styles.paginationInfo}>
            Page {currentPage + 1}
          </div>
          
          <div className={styles.paginationButtons}>
            {currentPage > 0 && (
              <motion.button
                className={`${styles.paginationButton} ${styles.prevButton}`}
                onClick={() => setCurrentPage((prev) => prev - 1)}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                <ChevronLeft size={16} />
                <span>Previous</span>
              </motion.button>
            )}
            {hasMoreNotifications && (
              <motion.button
                className={`${styles.paginationButton} ${styles.nextButton}`}
                onClick={() => setCurrentPage((prev) => prev + 1)}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                <span>Next</span>
                <ChevronRight size={16} />
              </motion.button>
            )}
          </div>
        </motion.div>
      )}
    </motion.div>
  );
};

export default NotificationsSidebarList;