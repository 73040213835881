import React from 'react';
import { getValidationAttributes } from '../../helpers/validationHelpers';
import styles from './DateField.module.css';

const DateField = ({ field, value, onChange, error, style = {} }) => {
    const validationAttributes = getValidationAttributes(field);

    return (
        <div className={styles.fieldContainer} style={style.container}>
            <label htmlFor={field.id} className={styles.label} style={style.label}>
                {field.label}
            </label>
            <input
                type="date"
                id={field.id}
                value={value}
                required={field.required}
                onChange={(e) => onChange(e.target.value)}
                className={styles.dateInput}
                style={style.input}
                {...validationAttributes}
            />
            {error && <p className={styles.error}>{error}</p>}
        </div>
    );
};

export default DateField;
