import React, { useEffect, useState } from 'react';
import { X, Maximize2, Minimize2, GripHorizontal } from 'lucide-react';
import Draggable from 'react-draggable';
import { motion, AnimatePresence } from 'framer-motion';
import styles from './DynamicPopup.module.css';

const DynamicPopup = ({ 
  title, 
  children, 
  onClose, 
  draggable = true, 
  style = {}, 
  expanded,
  className = '',
  showDragHandle = true
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    setIsExpanded(expanded);
  }, [expanded]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  const updateDimensions = () => {
    if (isExpanded) {
      return {
        width: 'calc(100% - 263px)',
        height: 'calc(100% - 4.5rem)',
        top: '4.5rem',
        left: '263px',
        transform: 'none',
        borderRadius: '0',
      };
    } else {
      return {
        minWidth: style.minWidth || '25rem',
        minHeight: style.minHeight || '15rem',
        top: '6.25rem',
        transform: 'none',
        borderRadius: '12px',
      };
    }
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const popupStyle = updateDimensions();

  const popupContent = (
    <motion.div
      initial={{ opacity: 0, scale: 0.95, y: 20 }}
      animate={{ opacity: 1, scale: 1, y: 0 }}
      exit={{ opacity: 0, scale: 0.95, y: 20 }}
      transition={{ 
        type: "spring",
        stiffness: 300,
        damping: 30 
      }}
      className={`${styles.popupContainer} ${className} ${isDragging ? styles.dragging : ''}`}
      style={{
        width: popupStyle.width,
        height: popupStyle.height,
        top: popupStyle.top,
        left: popupStyle.left,
        position: 'fixed',
        transform: popupStyle.transform,
        borderRadius: popupStyle.borderRadius,
        ...style,
      }}
    >
      <div className={styles.headerWrapper}>
        <div className={styles.dragHandle}>
          {showDragHandle && (
            <div className={styles.gripIcon}>
              <GripHorizontal size={16} strokeWidth={1.5} />
            </div>
          )}
          <h4 className={styles.popupHeader}>{title}</h4>
        </div>
        <div className={styles.iconWrapper}>
          <button 
            className={styles.iconButton} 
            onClick={toggleExpand}
            title={isExpanded ? 'Compress' : 'Expand'}
          >
            {isExpanded ? (
              <Minimize2 size={18} strokeWidth={1.5} className={styles.actionIcon} />
            ) : (
              <Maximize2 size={18} strokeWidth={1.5} className={styles.actionIcon} />
            )}
          </button>
          <button 
            className={`${styles.iconButton} ${styles.closeButton}`}
            onClick={onClose}
            title="Close"
          >
            <X size={18} strokeWidth={1.5} className={styles.actionIcon} />
          </button>
        </div>
      </div>
      <div className={styles.contentContainer}>
        {children}
      </div>
    </motion.div>
  );

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
        className={styles.overlay}
        onClick={onClose}
      />
      {draggable && !isExpanded ? (
        <Draggable
          handle={`.${styles.dragHandle}`}
          onStart={() => setIsDragging(true)}
          onStop={() => setIsDragging(false)}
          bounds="parent"
        >
          {popupContent}
        </Draggable>
      ) : (
        popupContent
      )}
    </AnimatePresence>
  );
};

export default DynamicPopup;