import { createStore, applyMiddleware } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootReducer from '../reducers'; // Assuming you have combined reducers here
import rootSaga from '../sagas/rootSaga'; // Import your root saga
import logger from 'redux-logger'; // Import redux-logger
import authSaga from '../sagas/authSaga'
// import { watchTokenExpiration } from '../sagas/watchTokenExpiration';
import authReducer from '../reducers/authReducer';
import { REAUTHENTICATE_REQUEST } from '../actions/authActions' // Action type

// Create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// const store = configureStore({
//   reducer: {
//     auth: authReducer, // Your auth reducer here
//     // other reducers
//   },
//   middleware: [sagaMiddleware],
// });

//Create the Redux store with Saga middleware
// const store = createStore(
//   rootReducer, // Your combined reducers
//   applyMiddleware(sagaMiddleware)
// );
// Custom middleware to track reAuthenticate actions
const trackReAuthenticateMiddleware = store => next => action => {
  if (action.type === REAUTHENTICATE_REQUEST) {
    console.log('reAuthenticate action dispatched:', action.payload);
  }
  return next(action);
};
const store = configureStore({
  reducer: rootReducer, // Your combined reducers here
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: false }) // Disabling thunk as we are using saga
      .concat(sagaMiddleware) // Adding saga middleware
      .concat(trackReAuthenticateMiddleware) // Adding custom middleware
      .concat(logger), 
});
// Run the root saga
sagaMiddleware.run(rootSaga);

export default store;
