import React from "react";
import CustomDropdown from "../../../components/reusableComponents/CustomDropdown/CustomDropdown";
import styles from "./MappingForm.module.css";

const MappingForm = ({
  isInputMapping,
  mappings,
  node,
  connectedSources,
  connectedTargets,
  handleMappingChange,
}) => {
  // Get selected values for input/output mapping
  const selectedSourceValue = mappings.input?.source || "";
  const selectedTargetValue = mappings.output?.target || "";

  return (
    <div className={styles.gridContainer}>
      {/* Source Node Dropdown */}
      <div className={styles.inputGridItem}>
        <label>
          {isInputMapping ? "Source Node:" : "Source Node (Current Node):"}
        </label>
        {isInputMapping ? (
          <CustomDropdown
            items={connectedSources.map((sourceNode) => ({
              value: sourceNode.id,
              label: sourceNode.data?.properties?.label || sourceNode.id,
            }))}
            value={selectedSourceValue}
            onChange={(e) =>
              handleMappingChange("input", "source", e.target.value)
            }
            style={{
              borderRadius: "12px",
              color: "#6B6B6B",
            }}
          />
        ) : (
          <input
            className={styles.input}
            type="text"
            value={node.data?.properties?.label || node.id}
            disabled
          />
        )}
      </div>

      {/* Target Node Dropdown */}
      <div className={styles.inputGridItem}>
        <label>
          {isInputMapping ? "Target Node (Current Node):" : "Target Node:"}
        </label>
        {isInputMapping ? (
          <input
            className={styles.input}
            type="text"
            value={node.data?.properties?.label || node.id}
            disabled
          />
        ) : (
          <CustomDropdown
            items={connectedTargets.map((targetNode) => ({
              value: targetNode.id,
              label: targetNode.data?.properties?.label || targetNode.id,
            }))}
            value={selectedTargetValue}
            onChange={(e) =>
              handleMappingChange("output", "target", e.target.value)
            }
            style={{
              borderRadius: "12px",
              color: "#6B6B6B",
            }}
          />
        )}
      </div>
    </div>
  );
};

export default MappingForm;
