import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styles from "./DetailViewSidebar.module.css";
import { ReactComponent as SystemSettingsIcon } from "../../../../assetts/images/systemSettings.svg";
import CustomDropdown from "../../../../components/reusableComponents/CustomDropdown/CustomDropdown";
import { fetchProcessFlowListApi } from "../../../../api/processFlowApi";
import { statusOptions, filterLogs, getStatusIcon, getStatusStyles } from "./logUtils";

const DetailViewSidebar = ({ onLogSelect }) => {
  const accessToken = useSelector((state) => state.auth.accessToken);

  const [logs, setLogs] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [highlightedLog, setHighlightedLog] = useState(null);
  const [error, setError] = useState(null);
  const defaultFromDate = "2022-10-10";
  const defaultToDate = "2022-10-10";

  useEffect(() => {
    if (logs.length > 0) return;

    const fetchLogs = async () => {
      try {
        const logsData = await fetchProcessFlowListApi(accessToken, {
          from_date: defaultFromDate,
          to_date: defaultToDate,
          status: selectedStatus === "All" ? "" : selectedStatus.toLowerCase(),
        });
        setLogs(logsData.results);
        setError(null);
        if (onLogSelect) onLogSelect(logsData?.results?.[0]);
      } catch (err) {
        setError("Error fetching logs. Please try again later.");
        console.error("Error fetching logs:", err);
      }
    };

    fetchLogs();
  }, [accessToken, selectedStatus, logs, onLogSelect]);
  const handleStatusChange = (value) => {
    setSelectedStatus(value);
  };

  const handleLogClick = (log) => {

    setHighlightedLog(log.id);
    if (onLogSelect) onLogSelect(log);
  };

  const filteredLogs = filterLogs(logs, selectedStatus);

  return (
    <div className={styles.sidebar}>
      <div className={styles.filterSection}>
        <div className={styles.filterItem}>
          <div className={styles.filterHeader}>
            <SystemSettingsIcon className={styles.filterIcon} />
            <span className={styles.filterTitle}>Date*</span>
          </div>
          <input type="text" value={defaultFromDate} readOnly className={styles.dateInput} />
          <span className={styles.dateSeparator}>to</span>
          <input type="text" value={defaultToDate} readOnly className={styles.dateInput} />
        </div>

        <div className={styles.filterItem}>
          <div className={styles.filterHeader}>
            <SystemSettingsIcon className={styles.filterIcon} />
            <span className={styles.filterTitle}>Status*</span>
          </div>
          <CustomDropdown
            items={statusOptions.map(({ value, label }) => ({ value, label }))}
            value={selectedStatus}
            onChange={handleStatusChange}
            style={{
              width: "100%",
              maxWidth: "261px",
              height: "53px",
              borderRadius: "12px",
              fontSize: "18.97px",
              lineHeight: "22.96px",
              color: "#6B6B6B",
            }}
          />
        </div>
      </div>
      <div className={styles.listSection}>
        {error ? (
          <p className={styles.error}>{error}</p>
        ) : (
          <ul className={styles.logList}>
            {filteredLogs.map((log) => (
              <div
                key={log.id}
                draggable
                className={`${styles.logItem} ${highlightedLog === log.id ? styles.highlighted : ""}`}
                style={getStatusStyles(log.status)}
                onClick={() => handleLogClick(log)}
              >
                <div className={styles.curveLine}></div>
                <div className={styles.dateTime}>
                  <span className={`${styles.logDate} ${highlightedLog === log.id ? styles.highlighted : ""}`}>{log.date}</span>
                  <span className={`${styles.logTime} ${highlightedLog === log.id ? styles.highlighted : ""}`}>{log.time}</span>
                </div>
                {getStatusIcon(log.status, styles)}
              </div>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default DetailViewSidebar;
