// api/integrationApi.js

export const fetchIntegrationTilesApi = async (accessToken, params, url = null) => {
    const baseUrl = url || `${process.env.REACT_APP_API_URL}/api/integration-list/`;

    const response = await fetch(baseUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify(params),
    });

    if (!response.ok) {
        throw new Error('Failed to fetch integration tiles');
    }

    return await response.json();
};

export const fetchTagsApi = async (accessToken) => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/tag-list/`;

    const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
        },
    });

    if (!response.ok) {
        throw new Error('Failed to fetch tags');
    }

    return await response.json();
};
