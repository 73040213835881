import { jwtDecode } from "jwt-decode";
const API_BASE_URL = process.env.REACT_APP_API_URL + "/api/"
export const isTokenValid = (accessToken) => {
  if (!accessToken) return false;

  try {
    const decodedToken = jwtDecode(accessToken);
   
    // Check if the token has expired
    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    let valid = decodedToken.exp > currentTime
    if (valid){

    } 
    return valid;
  } catch (error) {
    console.error('Invalid token', error);
    return false;
  }
};
export const refreshAccessToken = async () => {
  try {
    const refreshToken = localStorage.getItem('refreshToken');
    const response = await fetch(`${API_BASE_URL}refresh-token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ refreshToken }),
    });

    if (!response.ok) {
      throw new Error('Failed to refresh token');
    }

    const data = await response.json();
    const newAccessToken = data.accessToken;

    localStorage.setItem('accessToken', newAccessToken);
    return newAccessToken;
  } catch (error) {
    console.error('Error refreshing token:', error);
    throw error;
  }
};
export const handleTokenExpiry = async (refreshToken) => {
    try {
      const response = await fetch(`${API_BASE_URL}refresh-token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ refreshToken }),
      });
 
      if (response.ok) {
        const data = await response.json();
        return data; // Return the new tokens (access and refresh)
      } else {
        throw new Error('Unable to refresh token');
      }
    } catch (error) {
      console.error('Error refreshing token:', error);
      throw error;
    }
  };
 
  export const isTokenExpired = (token) => {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decodedToken.exp < currentTime;
  };

