import React, { useState, useEffect } from "react";
import styles from "./IntegrationsSidebar.module.css";
import { Tooltip } from "@mui/material";
import { ReactComponent as SystemSettingsIcon } from "../../../../assetts/images/systemSettings.svg";
import { ReactComponent as DataWrangler } from "../../../../assetts/images/dataWrangler.svg";
import { ReactComponent as Function } from "../../../../assetts/images/Function.svg";
import { ReactComponent as DropdownIconClosed } from "../../../../assetts/images/dropdownIconClosed.svg";
import { ReactComponent as DropdownIconOpen } from "../../../../assetts/images/dropdownIconOpen.svg";
import { ReactComponent as SearchIcon } from "../../../../assetts/images/searchIcon.svg";

const IntegrationsSidebar = ({ filters, functions, onTagSelectionChange }) => {
  const [isSystemOpen, setIsSystemOpen] = useState(false);
  const [isDataWranglerOpen, setIsDataWranglerOpen] = useState(false);
  const [isFunctionsOpen, setIsFunctionsOpen] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null); // Single selection state
  const [selectedFunction, setSelectedFunction] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  const handleTagChange = (tagId, tagType) => {
    // If switching categories, clear previous selection
    const newSelectedTag = selectedTag === tagId ? null : tagId;
    setSelectedTag(newSelectedTag);
    onTagSelectionChange(newSelectedTag, selectedFunction);
  };

  const handleFunctionChange = (func) => {
    const newSelectedFunction = selectedFunction === func ? null : func;
    setSelectedFunction(newSelectedFunction);
    onTagSelectionChange(selectedTag, newSelectedFunction);
  };

  const filteredSystemTags = filters.system.filter((filter) =>
    filter.name.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
  );

  const filteredDataWranglerTags = filters.dataWrangler.filter((filter) =>
    filter.name.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
  );

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleFunctionsToggle = () => {
    setIsFunctionsOpen(!isFunctionsOpen);
  };

  return (
    <div className={styles.IntegrationsSidebar}>
      <div className={styles.searchContainer}>
        <SearchIcon className={styles.searchIcon} />
        <input
          type="text"
          placeholder="Search..."
          className={styles.searchInput}
          value={searchTerm}
          onChange={handleSearch}
        />
        <hr className={styles.dottedLine} />
      </div>

      {/* By System Section */}
      <div className={styles.menu}>
        <div className={styles.listHeader} onClick={() => setIsSystemOpen(!isSystemOpen)}>
          <div className={styles.listHeaderContent}>
            <SystemSettingsIcon className={styles.icon} />
            <span className={styles.sectionTitle}>By System</span>
          </div>
          {isSystemOpen ? (
            <DropdownIconOpen className={styles.expandIcon} />
          ) : (
            <DropdownIconClosed className={styles.expandIcon} />
          )}
        </div>

        {isSystemOpen && (
          <ul className={styles.filterList}>
            {filteredSystemTags.map((filter) => (
              <li key={filter.id} className={styles.listItem}>
                <input
                  type="checkbox"
                  checked={selectedTag === filter.id}
                  className={styles.checkbox}
                  onChange={() => handleTagChange(filter.id, "System")}
                />
                <Tooltip title={filter.name} arrow>
                  <span className={styles.listItemText}>{filter.name}</span>
                </Tooltip>
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* Functions Section */}
      <div className={styles.menu}>
        <div className={styles.listHeader} onClick={handleFunctionsToggle}>
          <div className={styles.listHeaderContent}>
            <Function className={styles.icon} />
            <span className={styles.sectionTitle}>By Function</span>
          </div>
          {isFunctionsOpen ? (
            <DropdownIconOpen className={styles.expandIcon} />
          ) : (
            <DropdownIconClosed className={styles.expandIcon} />
          )}
        </div>

        {isFunctionsOpen && (
          <ul className={styles.filterList}>
            {functions.map((func, index) => (
              <li key={index} className={styles.listItem}>
                <input
                  type="checkbox"
                  checked={selectedFunction === func}
                  className={styles.checkbox}
                  onChange={() => handleFunctionChange(func)}
                />
                <Tooltip title={func} arrow>
                  <span className={styles.listItemText}>{func}</span>
                </Tooltip>
              </li>
            ))}
          </ul>
        )}
      </div>

       {/* Data Wrangler Section */}
       <div className={styles.menu}>
        <div className={styles.listHeader} onClick={() => setIsDataWranglerOpen(!isDataWranglerOpen)}>
          <div className={styles.listHeaderContent}>
            <DataWrangler className={styles.icon} />
            <span className={styles.sectionTitle}>Data Wrangler</span>
          </div>
          {isDataWranglerOpen ? (
            <DropdownIconOpen className={styles.expandIcon} />
          ) : (
            <DropdownIconClosed className={styles.expandIcon} />
          )}
        </div>

        {isDataWranglerOpen && (
          <ul className={styles.filterList}>
            {filteredDataWranglerTags.map((filter) => (
              <li key={filter.id} className={styles.listItem}>
                <input
                  type="checkbox"
                  checked={selectedTag === filter.id}
                  className={styles.checkbox}
                  onChange={() => handleTagChange(filter.id, "DataWrangler")}
                />
                <Tooltip title={filter.name} arrow>
                  <span className={styles.listItemText}>{filter.name}</span>
                </Tooltip>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default IntegrationsSidebar;
