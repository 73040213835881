import React from "react";
import CustomDropdown from "../../../components/reusableComponents/CustomDropdown/CustomDropdown";
import styles from "./TransformationForm.module.css"; // Importing specific styles for TransformationForm

const TransformationForm = ({
  isInputTransformation,
  transformations,
  node,
  connectedSources,
  connectedTargets,
  handleTransformationChange,
}) => {
  // Dummy rules for transformations
  const ruleOptions = [
    { value: "rule1", label: "Uppercase Transformation" },
    { value: "rule2", label: "Lowercase Transformation" },
    { value: "rule3", label: "Reverse String" },
    { value: "rule4", label: "Trim Spaces" },
  ];

  return (
    <div className={styles.gridContainer}>
      {/* Source Node */}
      <div className={styles.inputGridItem}>
        <label>
          {isInputTransformation
            ? "Source Node:"
            : "Source Node (Current Node):"}
        </label>
        {isInputTransformation ? (
          <CustomDropdown
            items={connectedSources.map((sourceNode) => ({
              value: sourceNode.id,
              label: sourceNode.data?.properties?.label || sourceNode.id,
            }))}
            value={transformations.input?.source || ""}
            onChange={(e) =>
              handleTransformationChange("input", "source", e.target.value)
            }
            style={{
              borderRadius: "12px",
              color: "#6B6B6B",
            }}
          />
        ) : (
          <input
            type="text"
            value={node.data?.properties?.label || node.id}
            disabled
          />
        )}
      </div>

      {/* Target Node */}
      <div className={styles.inputGridItem}>
        <label>
          {isInputTransformation
            ? "Target Node (Current Node):"
            : "Target Node:"}
        </label>
        {isInputTransformation ? (
          <input
            type="text"
            value={node.data?.properties?.label || node.id}
            disabled
          />
        ) : (
          <CustomDropdown
            items={connectedTargets.map((targetNode) => ({
              value: targetNode.id,
              label: targetNode.data?.properties?.label || targetNode.id,
            }))}
            value={transformations.output?.target || ""}
            onChange={(e) =>
              handleTransformationChange("output", "target", e.target.value)
            }
            style={{
              borderRadius: "12px",
              color: "#6B6B6B",
            }}
          />
        )}
      </div>

      {/* Transformation Rule Dropdown */}
      <div className={styles.inputGridItem}>
        <label>
          {isInputTransformation
            ? "Input Transformation Rule:"
            : "Output Transformation Rule:"}
        </label>
        <CustomDropdown
          items={ruleOptions.map(({ value, label }) => ({
            value,
            label,
          }))}
          value={
            transformations[isInputTransformation ? "input" : "output"]
              ?.transformation || ""
          }
          onChange={(e) =>
            handleTransformationChange(
              isInputTransformation ? "input" : "output",
              "transformation",
              e.target.value,
            )
          }
          style={{
            borderRadius: "12px",
            color: "#6B6B6B",
          }}
        />
      </div>
    </div>
  );
};

export default TransformationForm;
