import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { TextField, Button, Box, Typography, Grid, Divider, InputAdornment,Tooltip,  IconButton, Snackbar, Alert } from '@mui/material';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { LinkedIn } from 'react-linkedin-login-oauth2';
import logo from '../../assetts/images/RUNMYBOT_logo.svg'
import sideImage from '../../assetts/images/forgotpassword.svg'; // Replace with your side image path
import { Lock, Person } from '@mui/icons-material';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import cornerImage from '../../assetts/images/Corner_image.svg'; // Adjust the path as necessary
import HelpIcon from '@mui/icons-material/Help';

import '../../pages/Login.css';
import { useDispatch, useSelector } from 'react-redux';
import { refreshTokenRequest, setNewPasswordRequest } from '../../redux/actions/authActions';
import { isTokenValid } from '../../utils/tokenUtils';


const SetNewPassword = () => {
    const [email, setEmail] = useState('');
    const [placeholderText, setPlaceholderText] = useState("Email");
    const [placeholderTextPassword, setPlaceholderTextPassword] = useState("Password");
    const [password, setPassword] = useState('');
    const [passwordFocused, setPasswordFocused] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [inputValuePassword, setInputValuePassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const dispatch = useDispatch();
    const [focusedField, setFocusedField] = useState({});
    const { userId, token } = useParams();
     // Get login state from Redux
    const { isAuthenticated, successMessage, errorMessage, error } = useSelector((state) => state.auth);
    
  //   useEffect(() => {
  //     const token = localStorage.getItem("accessToken")
  //     const isValid =  (isTokenValid(token))
  //      console.log('isAuthenticated isValid  ',isValid, 'token ',token)
  //     //  if(isValid){
  //     //   dispatch({ type: 'LOGIN_SUCCESS', payload: { token } });
  //     //  }
  //     if (isAuthenticated) {
          

  //         if (isValid) {
  //             navigate('/dashboard', { state: { view: 'Activities' } });
  //         } else {
  //             refreshToken().then(() => {
  //                 navigate('/dashboard', { state: { view: 'Activities' } });
  //             }).catch(err => {
  //                 console.error('Error refreshing token:', err);
  //             });
  //         }
  //     }
  // }, [isAuthenticated]);
  const [open, setOpen] = useState(false);
    // State to manage form fields
    const [formFields, setFormFields] = useState({
      email: '',
      password: ''
    });
    
    const handleChange = (field) => (e) => {
      setFormFields((prev) => ({ ...prev, [field]: e.target.value }));
    };
    
    const handleFocus = (field) => {
      setFocusedField((prev) => ({ ...prev, [field]: true }));
    };
  
    const handleBlur = (field, value) => {
      if (!value) {
        setFocusedField((prev) => ({ ...prev, [field]: false }));
      }
    };

    const handleClickResetPassword = (e) => {
      setShowPassword(!showPassword);
      console.log('credentials .. ')
      e.preventDefault();
      const credentials = {
        password: formFields.password,
      };
      console.log('userId .. ',userId,'token  .. ',token, formFields.password)
      const payload = {
        userId,
        token,
        Password: formFields.password
    };
    
       // Dispatch login action
      dispatch(setNewPasswordRequest(payload));
    };

    
    const handleInputPasswordChange = (event) => {
      const newValue = event.target.value;
      setInputValuePassword(newValue);
      
      // Check if input value is empty and update placeholder if needed
      if (event.target.value.trim() === "") {
        setPlaceholderTextPassword("Password");
      } else {
        setPlaceholderTextPassword("");
      }
      setFormFields({
        ...formFields,
        password: event.target.value,
      });
    };
    const handleInputChange = (event) => {
      const newValue = event.target.value;
      setInputValue(newValue);
      
      // Check if input value is empty and update placeholder if needed
      if (event.target.value.trim() === "") {
        setPlaceholderText("Email");
      } else {
        setPlaceholderText("");
      }
      setFormFields({
        ...formFields,
        email: event.target.value,
      });
    };
   
    const handleOnFocusPassword = (event) =>{
      setPlaceholderTextPassword ("");
    }
    const handleInputBlurPassword = () => {
      // Perform some action when the input loses focus
      if (inputValue.trim() === "") {
        setPlaceholderTextPassword("Password");
      } else {
        setPlaceholderTextPassword("");
      }
    };
    const navigate = useNavigate();
    const handleOnFocus =(event)=>{
      setPlaceholderText("");
    }
    const handleInputBlur = () => {
      // Perform some action when the input loses focus
      if (inputValue.trim() === "") {
        setPlaceholderText("Email");
      } else {
        setPlaceholderText("");
      }
    };
    const handleClickShowPassword = () => {
      setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = () => {
      setShowPassword(true);
    };
    const handleMouseUpPassword = () => {
      setShowPassword(false);
    };
  // Navigate to login on successful registration
  useEffect(() => {
   
    console.log("LOGIN SUCCESS....",isAuthenticated)
    if (isAuthenticated) {
        navigate('/dashboard',{ state: { view:'Activities' } });
    }
  }, [isAuthenticated, navigate]);
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      // handleSetNewPassword(e); // Trigger submit on Enter key press
      handleClickResetPassword(e)
    }
  };
  const handleClose = () => {
    setOpen(false);
    navigate('/login');
  };
  useEffect(() => {
    console.log(successMessage)
    if(successMessage){
      setOpen(true)
      const timer = setTimeout(() => {
        // Dispatch to clear the signup message after the timeout
        // dispatch(clearSignupMessage());

        // Now navigate after the message is cleared
        navigate('/login');
      }, 5000);

      // Clean up the timer if the component unmounts before the timeout completes
      return () => clearTimeout(timer);
    }
  }, [successMessage, navigate]);
    const refreshToken = async () => {
      try {
          const response = await dispatch(refreshTokenRequest()); // Your Redux action to refresh token
          return response;
      } catch (error) {
          console.error('Failed to refresh token:', error);
          throw error;
      }
  };
    const handleGoogleLogin = (response) => {
      console.log('Google login success:', response);
      // Implement your Google login success logic here
      
      // navigate('/dashboard');
    };
  
    const handleGoogleLoginFailure = (response) => {
      console.error('Google login failed:', response);
    };
  
    const handleLinkedInSuccess = (response) => {
      console.log('LinkedIn login success:', response);
      // Implement your LinkedIn login success logic here
      navigate('/dashboard');
    };
  
    const handleLinkedInFailure = (response) => {
      console.error('LinkedIn login failed:', response);
    };
    
    return (
      <Grid container style={{ height: '100vh', backgroundColor:'#FFFFFF'}} spacing={0} onKeyDown={handleKeyDown}>
        {/* Login Form */}
        <Grid item xs={12} md={6} container direction="column" justifyContent="center" alignItems="center" spacing={0} padding={10} >
        <Snackbar open={open} autoHideDuration={5000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>
        <Box sx={{ width:'80%', textAlign: 'center', marginLeft: '70px' }}> {/* Adjusted marginLeft */}
          {/* Image on Top Left */}
          <Box sx={{ position: 'absolute', top: 0, left: 0 }}>
            <img src={cornerImage} alt="Login" style={{ width: '100px', height: 'auto' }} />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', top: 0 }}>
            <img src={logo} alt="Logo" style={{ width: '279.57px', marginBottom: '20px' }} />
          </Box>
          <Typography variant="body2" className="text-style">Enter a new password for your account</Typography>
          <Typography sx={{ mt:10, marginBottom: '-10px', textAlign: 'left',  }}>
            Password<span style={{ color: '#E27DDD' }}>*</span>
          </Typography>
          <TextField
              className='customTextFieldSignup'
              placeholder={!focusedField.password ? 'Enter your password' : ''}
              InputProps={{
                endAdornment: (
                  <Tooltip title="Minimum 8 characters and must include numbers.">
                    <IconButton>
                      <HelpIcon />
                    </IconButton>
                  </Tooltip>
                ),
                endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseUp={handleMouseUpPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    sx={{
                      color: 'rgba(0, 0, 0, 0.3)', // Adjust the alpha value here
                    }}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
              }}
              type={showPassword ? 'text' : 'password'}
              variant="outlined"
              fullWidth
              margin="normal"
              value={formFields.password}
              onChange={handleChange('password')}
              required
              onFocus={() => handleFocus('password')}
              onBlur={() => handleBlur('password', formFields.password)}
              sx={{ marginTop:'20px'  }} // Add left margin 
              
            />

          <Typography sx={{ marginBottom: '-10px', textAlign: 'left',  }}>
            Confirm Password<span style={{ color: '#E27DDD' }}>*</span>
          </Typography>
          <TextField
              className='customTextFieldSignup'
              placeholder={!focusedField.confirmPassword ? 'Re-enter your password' : ''}
              type="password"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formFields.confirm_password}
              onChange={handleChange('confirm_password')}
              required
              onFocus={() => handleFocus('confirm_password')}
              onBlur={() => handleBlur('confirm_password', formFields.confirm_password)}
              sx={{  marginTop:'20px' }} // Add left margin 
            />
      
          <Button variant="contained" className='loginbutton' fullWidth onClick={handleClickResetPassword} sx={{ mt: 2, boxShadow: 'none' }}>
            Reset
          </Button>

          
          {/* <Divider sx={{ my: 2, mb:6, fontSize: '14px' }}>or <b>Login</b> with Others</Divider> */}
          
          
          
        </Box>
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage} Please try again</p>}
      </Grid>

  
        {/* Side Image */}
        <Grid
          item
          xs={12}
          md={5}
          
          container
          justifyContent="center"  // Center horizontally
          alignItems="center"      // Center vertically
          style={{ backgroundColor: '#ffffff', height: '100vh'}}
        >
          <img
            src={sideImage}
            alt="Side"
            style={{
              width: '90%',       // Take full width of the container
              height: 'auto',      // Maintain aspect ratio
              maxWidth: '90vw',   // Restrict maximum width to viewport width
              maxHeight: '90vh',  // Restrict maximum height to viewport height
              objectFit: 'contain' // Adjust to ensure the whole image fits within bounds
            }}
          />
        </Grid>
      </Grid>
    );
  };
  
  export default SetNewPassword;
