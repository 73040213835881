// apiService
const BASE_URL = process.env.REACT_APP_API_URL+'/api/' // Replace with your API's base URL
// Create the body with the from_date and to_date
const daterange = {
    from_date: "2022-10-10", 
    to_date: "2022-10-10",
  };

const apiCall = async (endpoint, options = {}) => {

    const apiUrl = `${BASE_URL}${endpoint}`;

    const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...options.headers, 
        },
        body: JSON.stringify(daterange),
    });

    if (!response.ok) {
        throw new Error('Failed to fetch dashboard tiles');
    }

    return await response.json();
    
  };
  
  // Common API functions
  export const getDashboardTiles = async (accessToken) => {
    console.log(' getDashboardTiles == accessToken ',accessToken)
    const headers = {
      Authorization: `Bearer ${accessToken}`, // Include Bearer token in the headers
    };
    return await apiCall('dashboard-tiles/', { headers }); // API call to get dashboard tiles
  };

  export const getDashboardDataList = async (accessToken) => {
    const headers = {
      Authorization: `Bearer ${accessToken}`, // Include Bearer token in the headers
    };
    return await apiCall('dashboard/', { headers }); // API call to get dashboard tiles
  };