import React, { useState, useRef, useEffect } from 'react';
import styles from './CustomDropdown.module.css';
import { ReactComponent as DropdownIconClosed } from '../../../assetts/images/dropdownIconClosed.svg';
import { ReactComponent as DropdownIconOpen } from '../../../assetts/images/dropdownIconOpen.svg';

function CustomDropdown({ label, items, value, onChange, disabled, style, multiSelect = false }) {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const handleToggle = () => {
        if (!disabled) {
            setIsOpen(!isOpen);
        }
    };

    const handleSelect = (selectedValue) => {
        if (multiSelect) {
            // For multi-select, toggle the selected value in the array
            const newValue = value.includes(selectedValue)
                ? value.filter((val) => val !== selectedValue)
                : [...value, selectedValue];
            onChange( newValue );
        } else {
            // For single-select, set the selected value
            onChange(selectedValue);
            setIsOpen(false);
        }
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const selectedItemLabel = multiSelect
        ? items.filter((item) => value.includes(item.value)).map((item) => item.label).join(', ') || 'Select'
        : items.find((item) => item.value === value)?.label || 'Select';

    return (
        <div className={styles.dropdownContainer} style={style}>
            {label && <label className={styles.dropdownLabel}>{label}:</label>}
            <div className={styles.customDropdown} ref={dropdownRef} style={style}>
                <div
                    className={`${styles.dropdownHeader} ${disabled ? styles.disabled : ''}`}
                    style={style}
                    onClick={handleToggle}
                >
                    <span>{selectedItemLabel}</span>
                    {isOpen ? (
                        <DropdownIconOpen className={styles.expandIcon} />
                    ) : (
                        <DropdownIconClosed className={styles.expandIcon} />
                    )}
                </div>
                {isOpen && (
                    <ul className={styles.dropdownList}>
                        {items.map((item, index) => (
                            <li
                                key={index}
                                className={`${styles.dropdownItem} ${
                                    (multiSelect ? value.includes(item.value) : item.value === value)
                                        ? styles.selected
                                        : ''
                                }`}
                                onClick={() => handleSelect(item.value)}
                            >
                                {item.label}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
}

export default CustomDropdown;
