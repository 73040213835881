// api/workflowApi.js

export const fetchWorkflowNodesApi = async (accessToken, params) => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/task-list/`;

    const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify(params),
    });

    if (!response.ok) {
        throw new Error('Failed to fetch workflow nodes');
    }

    return await response.json();
};

export const fetchWorkflowTilesApi = async (accessToken, params) => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/workflow-library/`;

    const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify(params),
    });

    if (!response.ok) {
        throw new Error('Failed to fetch workflow tiles');
    }

    return await response.json();
};

export const fetchTagsApi = async (accessToken) => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/tag-list/`;

    const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
        },
    });

    if (!response.ok) {
        throw new Error('Failed to fetch tags');
    }

    return await response.json();
};

export const saveOrUpdateWorkflowApi = async (accessToken, workflowData, workflowId) => {
  const apiUrl = workflowId
    ? `${process.env.REACT_APP_API_URL}/api/edit-customer-workflow/${workflowId}/`
    : `${process.env.REACT_APP_API_URL}/api/create-customer-workflow/`;

  const method = 'POST';

  const response = await fetch(apiUrl, {
    method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(workflowData),
  });

  const responseData = await response.json();

  if (!response.ok) {
    let errorMessage = 'Failed to save or update workflow.'; // Default error message

    // Handle responses with a "name" key containing an array of messages
    if (responseData.name && Array.isArray(responseData.name)) {
      errorMessage = responseData.name.join(', '); // Join all messages from the "name" field
    }

    // Handle responses with an "errors" array that contains detailed error information
    else if (responseData.errors && Array.isArray(responseData.errors)) {
      const detailedErrors = responseData.errors.map(error => error.detail).filter(Boolean);
      errorMessage = detailedErrors.join(', ') || errorMessage; // Join all detailed messages or fallback
    }

    throw new Error(errorMessage); // Throw error with extracted message
  }

  return responseData; // Return parsed response if successful
};


  export const fetchWorkflowTemplates = async (accessToken) => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/customer-workflow-list/`;
  
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ name: "" }), // Send empty name in the body as specified
    });
  
    if (!response.ok) {
      throw new Error('Failed to fetch workflow templates');
    }
  
    return await response.json();
  };

  export const fetchWorkflowDataApi = async (accessToken, id, type) => {
    let apiUrl;
    
    if (type === 'template' || type === 'workflow') {
      apiUrl = `${process.env.REACT_APP_API_URL}/api/customer-workflow/${id}/`;
  } else if (type === 'basicIntegration' || type === 'basicWorkflow') {
      apiUrl = `${process.env.REACT_APP_API_URL}/api/integration-or-workflow/${id}/`;
  }
  
    const response = await fetch(apiUrl, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  
    if (!response.ok) {
      throw new Error('Failed to fetch workflow data');
    }
  
    return await response.json();
  };
  
