import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import DetailViewHeader from "../../components/DetailViewHeader/DetailViewHeader";
import { setMode } from "../../../../redux/actions/actions";
import DetailViewSidebar from "../../components/DetailViewSidebar/DetailViewSidebar";
import AutomationDetailsPage from "../../components/AutomationDetails/AutomationDetails";
import styles from "./DetailViewPage.module.css";

const DetailViewPage = () => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState([]);
  const [selectedLog, setSelectedLog] = useState(null);

  useEffect(() => {
    dispatch(setMode("view"));
    import("../../data/filterOptions.json").then((data) => setFilters(data.default));
  }, [dispatch]);

  const handleLogSelect = useCallback((log) => {
    setSelectedLog(log);
  }, []); // useCallback ensures handleLogSelect remains stable

  return (
    <div className={styles.DetailViewPage}>
      <div className={styles.headerAndContent}>
        <DetailViewHeader />
        <div className={styles.mainContent}>
          <DetailViewSidebar filters={filters} onLogSelect={handleLogSelect} />
          <div className={styles.contentContainer}>
            <AutomationDetailsPage selectedLog={selectedLog} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailViewPage;
