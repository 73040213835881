import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomTooltip from '../../reusableComponents/CustomTooltip/CustomTooltip';
import theme from '../../../theme';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { fetchWorkflowTemplates } from '../../../api/workflowApi'; 
const Schedule=({props}) =>{

    const navigate = useNavigate();

  // const breadcrumbs = useSelector(state => state.breadcrumbs.breadcrumbs);

  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');
  // Access token from the Redux store
  const accessToken = useSelector((state) => state.auth.accessToken);
    console.log("calendar accessToken... ",accessToken)
    useEffect(() => {
    const getTemplates = async () => {
      try {
        const data = await fetchWorkflowTemplates(accessToken); // Call the API to fetch templates
        setTemplates(data.results); // Set the 'results' array from the response
        setLoading(false); // Set loading to false when data is fetched
      } catch (error) {
        console.error('Failed to fetch templates:', error);
        setError(true); // Set error state if the API call fails
        setLoading(false); // Set loading to false even on error
      }
    };

    getTemplates(); // Fetch templates when the component mounts
  }, [accessToken]); // Access token as dependency to ensure the correct token is used
  const handleTimer = (id) => {
    console.log(`Start timer for template ${id}`);
  };

  const handleShutdown = (id) => {
     navigate(`/dashboard`)
  };

  const handleDelete = (id) => {
    console.log(`Delete template ${id}`);
  };
  const handleClickIntegration=()=>{
    console.log("handleClickIntegration")
    navigate('/integrations'); // Navigate to integrations page
  }
  const handleClickWorkflowLibrary=()=>{
    navigate('/workflow-library'); 
  }
    return (
        <Box>
            <Typography
                variant="h4"
                sx={{
                    ml:2,
                    fontFamily: 'Inter',           // Set font-family to 'Inter'
                    fontSize: '20px',              // Set font-size to 20px
                    fontWeight: 600,               // Set font-weight to 600
                    lineHeight: '24.2px',          // Set line-height to 24.2px
                    letterSpacing: '0.01em',       // Set letter-spacing to 0.01em
                    textAlign: 'left',             // Align text to the left
                }}
                >
                Schedulers
                </Typography>
                <Box
                    sx={{
                    borderRadius: '25px', // Outer border radius set to 25px
                    p: 2, // Padding inside the box
                    border: '1.81px solid #ECEEF6', // Applying the specific border style
                    mt: 3, // Optional margin-top adjustment
                    }}
                >
                    <TableContainer sx={{ mb: 2 }}>
                        <Table sx={{ borderCollapse: 'collapse' }}>
                        <TableHead>
                            <TableRow>
                            <TableCell sx={{ ...theme.typography.tableCellHeader }}>Name</TableCell>
                            <TableCell sx={{ ...theme.typography.tableCellHeader }}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {templates.map((template) => (
                            <TableRow key={template.id} sx={{
                                '&:last-child td, &:last-child th': { border: 0 },
                                height: '25px', // Adjust the row height to reduce spacing
                            }}>
                                
                                <TableCell
                                onClick={() => navigate(`/workflow-builder/${template.id}`, { state: { type: 'template' } })}
                                sx={{
                                    cursor: 'pointer',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    color: '#000000',
                                    fontWeight: 600,
                                    fontSize: '14px',
                                    ml:2,
                                    lineHeight:'16.94px',
                                    borderBottom: 'none', // No bottom border
                                    padding: '8px 14px', // Reduced padding
                                    userSelect: 'none', 
                                    '&:hover': {
                                    backgroundColor: '#f0f0f0', // Optional: Change background color on hover
                                    },
                                }}
                                >
                                {template.name}
                                </TableCell>

                                
                                <TableCell align="left" sx={{ borderBottom: 'none', padding: '4px 8px', ml: 5 , display: 'flex',alignItems: 'center', flexDirection: 'row',marginLeft: 0}}>
                                <CustomTooltip title="Edit/View">
                                    <IconButton
                                    onClick={() => handleTimer(template.id)}
                                    sx={{
                                        backgroundColor: '#F2F2F2',
                                        color: '#4F63BE',
                                        mt:1,
                                        mr: 2,
                                        p: 1,
                                        '&:hover': {
                                        backgroundColor: '#D9D9D9',
                                        },
                                    }}
                                    aria-label="timer"
                                    >
                                    <BorderColorOutlinedIcon sx={{ fontSize: 14 }} />
                                    </IconButton>
                                </CustomTooltip>

                                {/* <CustomTooltip title="Launch">
                                    <IconButton
                                    onClick={() => handleShutdown(template.id)}
                                    sx={{
                                        backgroundColor: '#F2F2F2',
                                        color: '#4F63BE',
                                        mt:1,
                                        mr: 2,
                                        p: 1,
                                        '&:hover': {
                                        backgroundColor: '#D9D9D9',
                                        },
                                    }}
                                    aria-label="shutdown"
                                    >
                                    <PowerSettingsNewIcon sx={{ fontSize: 14 }} />
                                    </IconButton>
                                </CustomTooltip> */}

                                <CustomTooltip title="Delete">
                                    <IconButton
                                    onClick={() => handleDelete(template.id)}
                                    sx={{
                                        backgroundColor: '#F2F2F2',
                                        color: '#4F63BE',
                                        mt:1,
                                        mr: 1,
                                        p: 1,
                                        '&:hover': {
                                        backgroundColor: '#D9D9D9',
                                        },
                                    }}
                                    aria-label="delete"
                                    >
                                    <DeleteOutlineOutlinedIcon sx={{ fontSize: 14 }} />
                                    </IconButton>
                                </CustomTooltip>
                                </TableCell>
                            </TableRow>
                            ))}
                        </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
        </Box>
            
    );
}

export default Schedule;