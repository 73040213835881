import React from "react";
import IntegrationTile from "../Tile/Tile";
import styles from "./Grid.module.css";

import dataIcon from "../../../../assetts/images/data.svg";
import catalogIcon from "../../../../assetts/images/catalog.svg";
import cartIcon from "../../../../assetts/images/cart.svg";

const iconMap = {
  data: dataIcon,
  catalog: catalogIcon,
  cart: cartIcon,
};

const Grid = ({ tiles, loading, error }) => {
  if (loading) {
    return <div>Loading integrations...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className={styles.gridContainer}>
      {tiles.map((tile) => (
        <IntegrationTile
          id={tile.id}
          icon={tile?.icon || null}
          title={tile.name}
          category={tile.functions.join("/")}
          info={tile.info}
        />
      ))}
    </div>
  );
};

export default Grid;
