import React from 'react';
import { TextField } from '@mui/material';
import InputMask from 'react-input-mask';
import PhoneMask from './PhoneMask';
import '../../'
const PhoneTextField = ({ value, onChange, ...props }) => {
    return (
        <InputMask
          mask="+1 (999) 999-9999"
          value={value}
          onChange={onChange}
          maskChar={null}  // Prevents displaying any extra characters
        >
          {() => (
            <TextField
              className='customTextFieldSignup'
              placeholder="+1 (555) 555-1234"
              variant="outlined"
              fullWidth
              value={value}
              onChange={onChange}
              InputProps={{
                style: { color: '#1C1C1C' }, // Apply text color
              }}
              InputLabelProps={{
                style: { color: '#1C1C1C' }, // Apply label color
              }}
              sx={{ marginLeft: '16px' , marginTop:'20px', mb:1.5  }} 
            />
          )}
        </InputMask>
      );
};

export default PhoneTextField;
