import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Bell, Check, ChevronLeft, ChevronRight, Loader } from "lucide-react";
import styles from "./NotificationsDropdown.module.css";
import NotificationItem from "./NotificationItem/NotificationItem";

function NotificationsDropdown({ 
    notifications,
    loading,
    markAsRead,
    markAsUnread,
    deleteNotifications
}) {
    const [currentPage, setCurrentPage] = useState(0);
    const notificationsPerPage = 5;
    const navigate = useNavigate();

    const startIndex = currentPage * notificationsPerPage;
    const paginatedNotifications = notifications.slice(startIndex, startIndex + notificationsPerPage);
    const hasUnreadNotifications = notifications.some(notification => !notification.isRead);
    const unreadCount = notifications.filter(n => !n.isRead).length;

    const handleNotificationClick = (notification) => {
        if (notification.actionUrl) {
            window.location.href = notification.actionUrl;
        } else {
            navigate(`/user-inbox/${notification.id}`);
        }
    };

    const handleMarkAllAsRead = () => {
        if (hasUnreadNotifications) {
            const unreadIds = notifications
                .filter(notification => !notification.isRead)
                .map(notification => notification.id);
            if (unreadIds.length > 0) {
                markAsRead(unreadIds);
            }
        }
    };

    if (loading) {
        return (
            <div className={styles.NotificationsDropdown}>
                <div className={styles.loading}>
                    <Loader className={styles.loadingIcon} size={20} />
                    <span>Loading notifications...</span>
                </div>
            </div>
        );
    }

    return (
        <div className={styles.NotificationsDropdown}>
            <div className={styles.header}>
                <div className={styles.headerLeft}>
                    <span className={styles.title}>
                        <Bell size={18} className={styles.bellIcon} />
                        Notifications
                    </span>
                </div>
                {hasUnreadNotifications && (
                    <button
                        className={styles.markAllButton}
                        onClick={handleMarkAllAsRead}
                        title="Mark all as read"
                    >
                        <Check size={16} />
                        <span>Mark all read</span>
                    </button>
                )}
            </div>

            <div className={styles.notificationsList}>
                {paginatedNotifications.length > 0 ? (
                    paginatedNotifications.map((notification) => (
                        <NotificationItem
                            key={notification.id}
                            notification={notification}
                            onNotificationClick={() => handleNotificationClick(notification)}
                            onToggleReadStatus={() => 
                                notification.isRead 
                                    ? markAsUnread([notification.id])
                                    : markAsRead([notification.id])
                            }
                            onDelete={() => deleteNotifications([notification.id])}
                        />
                    ))
                ) : (
                    <div className={styles.emptyState}>
                        <Bell size={24} className={styles.emptyIcon} />
                        <span>No notifications available</span>
                    </div>
                )}
            </div>

            {notifications.length > notificationsPerPage && (
                <div className={styles.paginationContainer}>
                    {currentPage > 0 && (
                        <button 
                            className={styles.paginationLink} 
                            onClick={() => setCurrentPage(prev => prev - 1)}
                        >
                            <ChevronLeft size={16} />
                            <span>Previous</span>
                        </button>
                    )}
                    {currentPage > 0 && 
                        (currentPage + 1) * notificationsPerPage < notifications.length && (
                        <span className={styles.buttonSpacer}>|</span>
                    )}
                    {(currentPage + 1) * notificationsPerPage < notifications.length && (
                        <button 
                            className={styles.paginationLink} 
                            onClick={() => setCurrentPage(prev => prev + 1)}
                        >
                            <span>Next</span>
                            <ChevronRight size={16} />
                        </button>
                    )}
                </div>
            )}
        </div>
    );
}

export default NotificationsDropdown;