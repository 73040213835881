import { combineReducers } from 'redux';

import authReducer from './authReducer';
import workflowReducer from './workflowReducer';
import breadcrumbReducer from './breadcrumbReducer';
import { notificationsReducer } from './notificationsReducer';

// Combine all reducers
const rootReducer = combineReducers({
  auth: authReducer, 
  workflow: workflowReducer,
  breadcrumbs: breadcrumbReducer,
  notifications: notificationsReducer,

});

export default rootReducer;
