import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import styles from './CustomTooltip.module.css';

const CustomTooltip = ({ children, title }) => {
  const [visible, setVisible] = useState(false);
  const [coords, setCoords] = useState({ top: 0, left: 0 });

  const showTooltip = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    setCoords({
      top: rect.top + rect.height + window.scrollY,
      left: rect.left + rect.width / 2 + window.scrollX,
    });
    setVisible(true);
  };

  const hideTooltip = () => {
    setVisible(false);
  };

  const tooltipContent = visible && (
    <div
      className={styles.tooltip}
      style={{ top: `${coords.top}px`, left: `${coords.left}px` }}
    >
      {title}
      <div className={styles.tooltipArrow}></div>
    </div>
  );

  return (
    <div
      className={styles.tooltipContainer}
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
    >
      {children}
      {ReactDOM.createPortal(tooltipContent, document.body)}
    </div>
  );
};

export default CustomTooltip;
