import React from 'react';

const UserManagement = () => {
  return (
    <div style={{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center', 
      justifyContent: 'center', 
      height: '100vh' // Makes the div full height of the viewport
    }}>
      <h1>User Management</h1>
      <p>This is the User Management content area. Work is in progress</p>
    </div>
  );
};

export default UserManagement;
