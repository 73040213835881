import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import styles from "./DetailViewHeader.module.css";
import backArrowRounded from "../../../../assetts/images/leftRoundedArrow.svg";
import { generateBreadcrumbElements } from "../../../../utils/breadcrumbUtils"; // Import the utility function

const DetailViewHeader = () => {
  const navigate = useNavigate();
  const breadcrumbs = useSelector((state) => state.breadcrumbs.breadcrumbs);

  const handleBackButtonClick = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <div className={styles.headerContainer}>
      <div className={styles.titleSection}>
        <h1 className={styles.title}>
          Sales Data Posting From POS To Oracle EBS
        </h1>
        <div className={styles.breadcrumb}>
          {generateBreadcrumbElements(breadcrumbs, navigate)}{" "}
          {/* Use the utility function */}
        </div>
      </div>
      <div className={styles.actionsSection}>
        <button className={styles.backButton} onClick={handleBackButtonClick}>
          <img src={backArrowRounded} alt="Back" className={styles.backIcon} />
        </button>
        <button className={styles.actionButton}>Audit Log</button>
      </div>
    </div>
  );
};

export default DetailViewHeader;
