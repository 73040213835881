import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import styles from "./Header.module.css";
import backArrowRounded from "../../../../assetts/images/leftRoundedArrow.svg";
import searchIcon from "../../../../assetts/images/searchIcon.svg";
import { generateBreadcrumbElements } from "../../../../utils/breadcrumbUtils";
import { Typography } from "@mui/material";

const Header = () => {
  const navigate = useNavigate();
  const breadcrumbs = useSelector((state) => state.breadcrumbs.breadcrumbs);

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  return (
    <div className={styles.headerContainer}>
      <div className={styles.titleSection}>
        <Typography sx={{color: 'text.secondary', textAlign: 'left', fontSize: '20px', fontWeight: 600, lineHeight: '24.2px', letterSpacing: '0.01em', mt:2.5 }}>
          <span style={{ fontFamily: "'Inter', sans-serif" }}> Experience </span><span style={{ fontFamily: "'Red Hat Display', sans-serif" , fontStyle: 'italic'}}>RUN</span><span style={{ fontStyle: 'normal', fontWeight:'400' }}>MY</span>BOT
        </Typography>
        <div className={styles.breadcrumb}>
          {generateBreadcrumbElements(breadcrumbs, navigate)}
        </div>
      </div>
      <div className={styles.searchSection}>
        <button className={styles.backButton} onClick={handleBackButtonClick}>
          <img src={backArrowRounded} alt="Back" className={styles.backIcon} />
        </button>
        <div className={styles.searchWrapper}>
          <img src={searchIcon} alt="Search" className={styles.searchIcon} />
          <input
            type="text"
            className={styles.searchInput}
            placeholder="Search..."
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
