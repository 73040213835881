// ReAuth.js
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { reAuthenticationRequest } from './redux/actions/authActions';

const ReAuth = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      dispatch(reAuthenticationRequest(token));
    }
  }, [dispatch]);

  return null; // No UI, only dispatches action on mount
};

export default ReAuth;
