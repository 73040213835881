import React from 'react';

const Profile = () => {
  return (
    <div style={{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center', 
      justifyContent: 'center', 
      height: '100vh' // Makes the div full height of the viewport
    }}>
      <h1>Profile</h1>
      <p>This is the Profile content area.  Work is in progress</p>
    </div>
  );
};

export default Profile;
