// sagas/watchTokenExpiration.js

import { delay, put, select, take } from 'redux-saga/effects';
import { REFRESH_TOKEN_REQUEST } from '../actions/authActions';

export function* watchTokenExpiration() {
    while (true) {
        const { accessToken } = yield select(state => state.auth);

        if (accessToken) {
            const jwtPayload = JSON.parse(atob(accessToken.split('.')[1]));
            const expTime = jwtPayload.exp * 1000;
            const currentTime = new Date().getTime();
            const timeLeft = expTime - currentTime;

            if (timeLeft <= 0) {
                yield put({ type: REFRESH_TOKEN_REQUEST });
            } else {
                yield delay(timeLeft);
                yield put({ type: REFRESH_TOKEN_REQUEST });
            }
        }

        yield take('LOGIN_SUCCESS');
    }
}
