// StatusCard.js
import React from 'react';
import { Card, CardContent, Box, Typography } from '@mui/material';

const StatusCard = ({ title, count, percentage, percent_image }) => {
  return (
    <Card className="card" sx={{ border: '1px solid #d3d3d3',boxSizing: 'border-box' }}>
      <CardContent sx={{ p: 1, position: 'relative' }}>
        {/* Percentage Box */}
        <Box
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            backgroundColor: '#ffffff',
            color: '#4F63BE',
            borderRadius: '24px',
            padding: '4px 4px',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight:'16.94px'
          }}
        >
          <img src={percent_image} alt="Image 1" style={{ marginRight: 4 }} /> {percentage}
        </Box>

        {/* Card Content */}
        <Typography className="statusCardTitle" sx={{ mb: 1 }}> {title} </Typography>
        <Typography className="statusCardCount">{count}</Typography>
        <Typography sx={{fontSize: '14px', lineHeight:'16.94px', letterSpacing:'1%', fontWeight:'400', marginBottom:'-18px', color: '#949494' }} >Automation</Typography>
      </CardContent>
    </Card>
  );
};

export default StatusCard;
