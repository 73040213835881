import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { socialMediaLoginCallback } from '../../redux/actions/authActions';

const SocialMediaCallback = () => {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const { isAuthenticated } = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const location = useLocation();
    
    // Use state for provider to trigger re-renders when it changes
    const [provider, setProvider] = useState(null);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const currentUrl = `${window.location.origin}`;
        const code = searchParams.get('code');
        
        // Extract the provider from the URL and set it in state
        const providerMatch = location.pathname.match(/social\/([^/]+)\/login/);
        const providerName = providerMatch ? providerMatch[1] : null;
        setProvider(providerName);

        // Combine into one object
        const loginData = {
            provider: providerName,
            code,
            currentUrl
        };

        if (code) {
            dispatch(socialMediaLoginCallback(loginData));
        }
    }, [dispatch, searchParams, location.pathname]);

    // Redirect to dashboard if authenticated
    useEffect(() => {
        if (isAuthenticated) {
            navigate('/dashboard', { state: { view: 'Activities' } }); // Replace with your dashboard route
        }
    }, [isAuthenticated, navigate]);

    return (
        <div>
            <p>Processing {provider} login...</p>
        </div>
    );
};

export default SocialMediaCallback;
