import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Clock, CheckCircle, Circle, Trash2, ExternalLink } from 'lucide-react';
import styles from "./NotificationContent.module.css";
import warningRound from '../../../../assetts/images/warningRound.svg';
import alertRound from '../../../../assetts/images/alertRound.svg';
import reminderRound from '../../../../assetts/images/reminderRound.svg';
import actionRequiredRound from '../../../../assetts/images/actionRequiredRound.svg';

const NotificationContent = ({ 
  notification, 
  onDelete, 
  onMarkAsRead,
  onMarkAsUnread 
}) => {
  if (!notification) {
    return (
      <div className={styles.noSelection}>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className={styles.emptyState}
        >
          <img src={reminderRound} alt="No messages" className={styles.emptyIcon} />
          <h3>No Messages Yet</h3>
          <p>You don't have any messages in your inbox.</p>
        </motion.div>
      </div>
    );
  }

  const getNotificationIcon = (type) => {
    switch(type?.toLowerCase()) {
      case 'warning':
        return warningRound;
      case 'alert':
        return alertRound;
      case 'reminder':
        return reminderRound;
      case 'action_required':
      case 'action required':
        return actionRequiredRound;
      default:
        return reminderRound;
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={styles.notificationContent}
    >
      <div className={styles.header}>
        <div className={styles.headerMain}>
          <motion.img
            src={getNotificationIcon(notification?.notificationType)}
            alt={notification?.notificationType || 'Notification'}
            className={styles.typeIcon}
            whileHover={{ scale: 1.1 }}
          />
          <div className={styles.headerText}>
            <h3 className={styles.senderName}>{notification?.notificationType}</h3>
            <div className={styles.timeWrapper}>
              <Clock size={14} />
              <span className={styles.timestamp}>{notification.timestamp}</span>
            </div>
          </div>
        </div>

        <div className={styles.actionLinks}>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className={`${styles.actionButton} ${notification.isRead ? styles.unreadButton : styles.readButton}`}
            onClick={() => notification.isRead ? onMarkAsUnread() : onMarkAsRead()}
          >
            {notification.isRead ? <Circle size={16} /> : <CheckCircle size={16} />}
            <span>Mark as {notification.isRead ? 'Unread' : 'Read'}</span>
          </motion.button>

          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className={`${styles.actionButton} ${styles.deleteButton}`}
            onClick={onDelete}
          >
            <Trash2 size={16} />
            <span>Delete</span>
          </motion.button>
        </div>
      </div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.1 }}
        className={styles.contentSection}
      >
        <h2 className={styles.title}>{notification.title}</h2>
        
        <div className={styles.body}>
          <p className={styles.description}>{notification.description}</p>
          {notification.content && (
            <p className={styles.additionalText}>{notification.content}</p>
          )}
          <div className={styles.signature}>
            <p className={styles.footerText}>
              Have a nice day,<br />
              <span className={styles.signatureName}>Yadu</span>
            </p>
          </div>
        </div>

        {notification.actionUrl && (
          <motion.div 
            className={styles.actions}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
          >
            <motion.button 
              className={styles.primaryButton}
              onClick={() => window.location.href = notification.actionUrl}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              <span>Go to Action</span>
              <ExternalLink size={16} />
            </motion.button>
          </motion.div>
        )}
      </motion.div>
    </motion.div>
  );
};

export default NotificationContent;