import React from "react";
import styles from "./DialogBox.module.css";

const DialogBox = ({ content, onClose, customStyles }) => {
  return (
    <div className={styles.dialogBox} style={customStyles}>
      <div className={styles.content}>
        {content}
      </div>
      <button className={styles.closeButton} onClick={onClose}>
        ×
      </button>
    </div>
  );
};

export default DialogBox;
