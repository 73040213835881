const initialState = {
    notifications: [] 
};

export const notificationsReducer = (state = initialState, action) => {

    switch (action.type) {
        case 'UPDATE_NOTIFICATIONS':
            return {
                ...state,
                notifications: action.payload
            };
        default:
            return state;
    }
};