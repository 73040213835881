import React from "react";
import styles from "./ConfigurationHeader.module.css";
import arrowRight from "../../../assetts/images/dropdownIconOpen.svg";
import arrowDown from "../../../assetts/images/dropdownIconClosed.svg";
import plusIcon from "../../../assetts/images/plus.svg";

const ConfigurationHeader = ({
  fieldKey,
  value,
  path,
  openSections,
  toggleSection,
  renderSection,
  handleAddField,
}) => {
  const isOpen = openSections[path.join(".")];
  const hasNestedProperties =
    value.properties && Object.keys(value.properties).length > 0;

  const snakeCaseToCapitalized = (str) =>
    str
      .split("_")
      .map((word) => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
      .join(" ");

  return (
    <li className={styles.listItem}>
      {hasNestedProperties && (
        <>
          <hr />
          <div className={styles.header}>
            <div className={styles.leftWrap}>
              {value.dynamic && (
                <img
                  className={styles.addButton}
                  onClick={() => handleAddField(path)}
                  src={plusIcon}
                  alt="Add"
                />
              )}
              <span className={styles.key}>
                {snakeCaseToCapitalized(fieldKey)}
              </span>
            </div>
            <div
              className={
                isOpen ? styles.toggleButtonActive : styles.toggleButton
              }
              onClick={() => toggleSection(path.join("."))}
            >
              <img src={isOpen ? arrowDown : arrowRight} alt="Toggle" />
            </div>
          </div>
          {isOpen && (
            <div className={styles.nested}>
              {renderSection(value.properties, path)}
            </div>
          )}
        </>
      )}
    </li>
  );
};

export default ConfigurationHeader;
