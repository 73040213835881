import React, { useState, useEffect } from "react";
import styles from "./Sidebar.module.css";
import { Tooltip } from "@mui/material";
import { ReactComponent as SystemSettingsIcon } from "../../../../assetts/images/systemSettings.svg";
import { ReactComponent as DataWrangler } from "../../../../assetts/images/dataWrangler.svg";
import { ReactComponent as DropdownIconClosed } from "../../../../assetts/images/dropdownIconClosed.svg";
import { ReactComponent as DropdownIconOpen } from "../../../../assetts/images/dropdownIconOpen.svg";
import { ReactComponent as SearchIcon } from "../../../../assetts/images/searchIcon.svg";

const Sidebar = ({ filters, onTagSelectionChange }) => {
  const [isSystemOpen, setIsSystemOpen] = useState(false);
  const [isDataWranglerOpen, setIsDataWranglerOpen] = useState(true);
  const [selectedTag, setSelectedTag] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [dataWrangler, setDataWrangler] = useState([
    { id: 1, name: "File Transformation" },
    { id: 2, name: "File Conversion" },
  ]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  const handleCheckboxChange = (tagId) => {
    const newSelectedTag = selectedTag === tagId ? null : tagId;
    setSelectedTag(newSelectedTag);
    onTagSelectionChange(newSelectedTag ? newSelectedTag : "");
  };

  const filteredFilters = filters.filter((filter) =>
    filter.name?.toLowerCase().includes(debouncedSearchTerm?.toLowerCase())
  );

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className={styles.IntegrationsSidebar}>
      <div className={styles.searchContainer}>
        <SearchIcon className={styles.searchIcon} />
        <input
          type="text"
          placeholder="Search..."
          className={styles.searchInput}
          value={searchTerm}
          onChange={handleSearch}
        />
        <hr className={styles.dottedLine} />
      </div>

      {/* <div className={styles.menu}>
        <div className={styles.listHeader} onClick={() => setIsSystemOpen(!isSystemOpen)}>
          <div className={styles.listHeaderContent}>
            <SystemSettingsIcon className={styles.icon} />
            <span className={styles.sectionTitle}>By System</span>
          </div>
          {isSystemOpen ? (
            <DropdownIconOpen className={styles.expandIcon} />
          ) : (
            <DropdownIconClosed className={styles.expandIcon} />
          )}
        </div>

        {isSystemOpen && (
          <ul className={styles.filterList}>
            {filteredFilters.map((filter) => (
              <li key={filter.id} className={styles.listItem}>
                <input
                  type="checkbox"
                  checked={selectedTag === filter.id}
                  className={styles.checkbox}
                  onChange={() => handleCheckboxChange(filter.id)}
                />
                <Tooltip title={filter.name} arrow>
                  <span className={styles.listItemText}>{filter.name}</span>
                </Tooltip>
              </li>
            ))}
          </ul>
        )}
      </div> */}

      <div className={styles.menu}>
        <div className={styles.listHeader} onClick={() => setIsDataWranglerOpen(!isDataWranglerOpen)}>
          <div className={styles.listHeaderContent}>
            <DataWrangler className={styles.icon} />
            <span className={styles.sectionTitle}>Rules Templates</span>
          </div>
          {isDataWranglerOpen ? (
            <DropdownIconOpen className={styles.expandIcon} />
          ) : (
            <DropdownIconClosed className={styles.expandIcon} />
          )}
        </div>

        {isDataWranglerOpen && (
          <ul className={styles.filterList}>
            {dataWrangler.map((filter) => (
              <li key={filter.id} className={styles.listItem}>
                <input
                  type="checkbox"
                  checked={selectedTag === filter.id} 
                  className={styles.checkbox}
                  onChange={() => handleCheckboxChange(filter.id)}
                />
                <Tooltip title={filter.name} arrow>
                  <span className={styles.listItemText}>{filter.name}</span>
                </Tooltip>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
