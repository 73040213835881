import React, { useContext }  from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { AuthContext } from './AuthProvider';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ element: Element, ...rest }) => {
  // const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  
  const location = useLocation();
  // Debugging
  console.log("Is Authenticated:", isAuthenticated);
  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  console.log("Is <Outlet />:", <Outlet />);
  return <Outlet />; 
};

export default PrivateRoute;
