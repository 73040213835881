import React from 'react';
import { Grid, Box, Typography, Card, CardContent } from '@mui/material';

// import IntegrationChart from '../../components/IntegrationChart'; // Placeholder for a chart component


import MyWorkflows from './MyWorkflows';
import Activities from './Activities';
import { useLocation } from 'react-router-dom';
const DashboardOverview = () => {
  const location = useLocation();
  const { view } = location.state || {};
  console.log('view' ,view)
  const renderComponent = () => {
    switch (view) {
      case 'Activities':
        return <Activities />;
      case 'Workflows':
      default:
        return <MyWorkflows />;
    }
  };
  return (
    <Box sx={{ flexGrow: 1, p: 5 }}>      
      {/* Header */}

      <Grid container spacing={1} sx={{ mt: 5, border:'none', p:0 }}>
        <Grid item xs={12}>
        {renderComponent()}
      </Grid>
  </Grid> 
      

      
    </Box>
  );
};

export default DashboardOverview;
