import React from 'react';
import  EventPro from '../components/eventpro/index';
import { useSelector } from 'react-redux';
const EventProPage = () => {
  const accessToken = useSelector((state) => state.auth.accessToken);
  console.log("EventProPage  accessToken... ",accessToken)
  return (
    <div style={{ marginTop:'79px' }}>
       <EventPro />
    </div>
  );
};

export default EventProPage;
