import React, { useEffect } from 'react';
import { Routes, Route, useNavigate} from 'react-router-dom';
import useRouteBreadcrumbs from './hooks/useRouteBreadcrumbs';
import Settings from './pages/Settings';
import DashboardLayout from './layouts/DashboardLayout';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Reports from './pages/Reports';
import Profile from './pages/Profile';
import UserManagement from './pages/UserManagement';
import Logs from './pages/Logs';
import MyTemplates from './pages/MyTemplates';
import Inbox from './pages/notifications/inbox/Inbox';
import PrivateRoute from './PrivateRoute';

import Dashboard from './pages/Dashboard';
import DashboardOverview from './components/DashboardOverview';
import WorkflowLibraryPage from './pages/workflows/pages/WorkflowLibraryPage/WorkflowLibraryPage';
import IntegrationsLibraryPage from './pages/integrations/pages/IntegrationsLibraryPage/IntegrationsLibraryPage';
import DetailViewPage from './features/DetailView.js/pages/DetailViewPage/DetailViewPage';

import WorkflowBuilder from './features/workflowBuilder/WorkflowBuilder/WorkflowBuilder';
import EventProPage from './pages/EventProPage';
import ForgotPassword from './pages/password-reset/ForgotPassword';
import SetNewPassword from './pages/password-reset/SetNewPassword'
import ExpRunmybotPage from './pages/exp-runmybot/pages/ExpRunmybotPage/ExpRunmybotPage';
import GoogleLoginCallback from './components/authenthication/GoogleLoginCallback';
import SocialMediaCallback from './components/authenthication/SocialMediaCallback';

const AppRoutes = () => {
  useRouteBreadcrumbs();

  const navigate = useNavigate();  

  useEffect(() => {
    // Check if there is a stored page in localStorage
    const storedPage = sessionStorage.getItem('currentPage');
    if (storedPage) {
      navigate(storedPage); // Redirect to the stored page after reload
    }
  }, [navigate]);
  return (
    <Routes>
       <Route path="/login" element={<Login />} />
       <Route path="/signup" element={<Signup />} />
       <Route path="/forgot-password" element={<ForgotPassword/>} />
       <Route path="/setnew-password/:userId/:token" element={<SetNewPassword/>} />
       <Route path="/accounts/social/google/login/callback" element = {<SocialMediaCallback />} />
       <Route path="/accounts/social/microsoft/login/callback" element = {<SocialMediaCallback />} />
       <Route path="/accounts/social/linkedin/login/callback" element = {<SocialMediaCallback />} />
       <Route element={<DashboardLayout />}>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="dashboard" element={<DashboardOverview />} />
          <Route path="integrations" element={<IntegrationsLibraryPage />} />
          <Route path="workflow-library" element={<WorkflowLibraryPage />} />
          <Route path="exp-runmybot" element={<ExpRunmybotPage />} />
          <Route path="user-management" element={<UserManagement />} />
          <Route path="event-pro" element={<EventProPage />} />
          <Route path="my-templates" element={<MyTemplates />} />
          <Route path="user-inbox/:notificationId?" element={<Inbox />} />
          <Route path="logs" element={<Logs />} />
          <Route path="profile" element={<Profile />} />
          <Route path="reports" element={<Reports />} />
          <Route path="settings" element={<Settings />} />
          <Route path="/process-flow/:id" element={<DetailViewPage />} />
          <Route path="/workflow-builder" element={<WorkflowBuilder />} />
          <Route path="/workflow-builder/:id" element={<WorkflowBuilder />} />
        </Route>
        <Route path="/" element={<Login />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
