import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Box, Typography, Grid, Tooltip, IconButton } from '@mui/material';
import logo from '../assetts/images/RUNMYBOT_logo.svg'
import sideImage from '../assetts/images/side-image-signup.svg'; // Replace with your side image path
import cornerImage from '../assetts/images/Corner_image2.svg'; 
import './Login.css'
import { useDispatch, useSelector } from 'react-redux';
import { clearSignupMessage, registerRequest } from '../redux/actions/authActions';
import HelpIcon from '@mui/icons-material/Help';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import PhoneTextField from '../components/common/PhoneTextField';

const Signup = () => {
  const [formFields, setFormFields] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    password: '',
    confirm_password: '',
  });
  const [focusedField, setFocusedField] = useState({});
  const dispatch = useDispatch();
  const { registering, registrationSuccess, registrationError } = useSelector(state => state.auth);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const formatPhoneNumberForDB = (phoneNumber) => {
    // Strip all non-numeric characters except for the '+' symbol
    const cleanedNumber = phoneNumber.replace(/\D/g, '');

    // Add the country code (for example, +1 for USA)
    const countryCode = '+1';
    return `${countryCode}${cleanedNumber}`;
  };
  const handleSignup = (e) => {
    // Implement your signup logic here
    e.preventDefault();

    const formattedPhoneNumber = formatPhoneNumberForDB(formFields.phone_number);
    console.log("....... formattedPhoneNumber ",formattedPhoneNumber)
    // Update the phone_number field with the formatted value
      setFormFields({ ...formFields, ['phone_number']: formattedPhoneNumber });
    console.log("....... registerRequest ",formFields)
    dispatch(registerRequest(formFields));

  };
  
  // Navigate to login on successful registration
  useEffect(() => {
    console.log(registrationSuccess)
    if(registrationSuccess){
      setOpen(true)
      const timer = setTimeout(() => {
        // Dispatch to clear the signup message after the timeout
        // dispatch(clearSignupMessage());

        // Now navigate after the message is cleared
        navigate('/login');
      }, 5000);

      // Clean up the timer if the component unmounts before the timeout completes
      return () => clearTimeout(timer);
    }
  }, [registrationSuccess, navigate]);

  const handleFocus = (field) => {
    setFocusedField((prev) => ({ ...prev, [field]: true }));
  };

  const handleBlur = (field, value) => {
    if (!value) {
      setFocusedField((prev) => ({ ...prev, [field]: false }));
    }
  };
  const handleClose = () => {
    setOpen(false);
    navigate('/login');
  };
  const handleChange = (field) => (e) => {
    setFormFields((prev) => ({ ...prev, [field]: e.target.value }));
  };
  // Handle 'Enter' key press for submission
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSignup(e); // Trigger form submit when 'Enter' is pressed
    }
  };

  const [showFirstNameLabel, setShowFirstNameLabel] = useState(false);
  const [showLastNameLabel, setShowLastNameLabel] = useState(false);
  const [showEmailLabel, setShowEmailLabel] = useState(false);
  const [showPhoneNumberLabel, setShowPhoneNumberLabel] = useState(false);
  const [showPasswordLabel, setShowPasswordLabel] = useState(false);
  const [showConfirmPasswordLabel, setShowConfirmPasswordLabel] = useState(false);
  const [phone, setPhone] = useState('');
  return (
    <Grid container style={{ height: '100vh' , backgroundColor:'#FFFFFF'  }} spacing={0} onKeyDown={handleKeyDown}>
       
      {/* Signup Form */}
      <Grid item xs={12} md={6} container direction="column" justifyContent="center" alignItems="center"  spacing={0} padding={10}>
      
      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          {registrationSuccess}
        </Alert>
      </Snackbar>
      <Box sx={{ textAlign: 'center', marginLeft: '70px' , maxWidth:470 }}>
      {/* Image on Top Left */}
      <Box sx={{ position: 'absolute', top: 0, left: 0 }}>
        <img src={cornerImage} alt="Login" style={{ width: '100px', height: 'auto' }} />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', top: 0 }}>
        <img src={logo} alt="Logo" style={{ width: '279.57px', marginBottom: '20px' }} />
      </Box>
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          fontFamily: 'Inter, sans-serif', // Make sure to import the Inter font in your project
          fontSize: '40px',
          fontWeight: 600,
          lineHeight: '48.41px',
          textAlign: 'left',
          color:'#7F7F7F',
          mb:6
        }}
      >
        Create an account
      </Typography>

      {/* First Name and Last Name Inputs */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography sx={{ marginBottom: '-10px', textAlign: 'left' }}>
            First Name<span style={{ color: '#E27DDD' }}>*</span>
          </Typography>
          <TextField
            className='customTextFieldSignup'
            placeholder={!focusedField.firstName ? 'First Name' : ''}
            
            variant="outlined"
            fullWidth
            margin="normal"
            value={formFields.first_name}
            onChange={handleChange('first_name')}
            required
            onFocus={() => handleFocus('first_name')}
            onBlur={() => handleBlur('first_name', formFields.first_name)}
            
          />
      </Grid>

      {/* Last Name Input */}
      <Grid item xs={12} sm={6}>
      <Typography sx={{ marginBottom: '-10px', textAlign: 'left' }}>
        Last Name<span style={{ color: '#E27DDD' }}>*</span>
      </Typography>
      <TextField
          className='customTextFieldSignup'
          placeholder={!focusedField.lastName ? 'Last Name' : ''}
          
          variant="outlined"
          fullWidth
          margin="normal"
          value={formFields.last_name}
          onChange={handleChange('last_name')}
          required
          onFocus={() => handleFocus('last_name')}
          onBlur={() => handleBlur('last_name', formFields.last_name)}
          
        />
      </Grid>

      {/* Email Address Input */}
      <Typography sx={{ marginBottom: '-10px', textAlign: 'left', marginLeft: '16px'  }}>
      Email Address <span style={{ color: '#A3A2A5' }}>(Use Business ID)</span><span style={{ color: '#E27DDD' }}>*</span>
      </Typography>
      <TextField
          className='customTextFieldSignup'
          placeholder={!focusedField.email ? 'yourname@actionfi.com' : ''}
          
          variant="outlined"
          fullWidth
          margin="normal"
          value={formFields.email}
          onChange={handleChange('email')}
          required
          onFocus={() => handleFocus('email')}
          onBlur={() => handleBlur('email', formFields.email)}
          sx={{ marginLeft: '16px' , marginTop:'20px'   }} // Add left margin here
        />
        <Typography sx={{ marginBottom: '-10px', textAlign: 'left', marginLeft: '16px'  }}>
          Phone Number
        </Typography>
        <PhoneTextField
          value={formFields.phone_number}
          onChange={handleChange('phone_number')}
          // onChange={(e) => setPhone(e.target.value)}
        />
        {/* <TextField
          className='customTextFieldSignup'
          placeholder={!focusedField.phone_number ? '(555) 555-1234' : ''}
          variant="outlined"
          fullWidth
          margin="normal"
          value={formFields.phone_number}
          onChange={handleChange('phone_number')}
          required
          onFocus={() => handleFocus('phone_number')}
          onBlur={() => handleBlur('phone_number', formFields.phone_number)}
          sx={{ marginLeft: '16px' , marginTop:'20px'   }} // Add left margin 
          
        /> */}
      

      {/* Password Input */}
      <Typography sx={{ marginBottom: '-10px', textAlign: 'left', marginLeft: '16px'  }}>
        Password<span style={{ color: '#E27DDD' }}>*</span>
      </Typography>
      <TextField
          className='customTextFieldSignup'
          placeholder={!focusedField.password ? 'Enter your password' : ''}
          InputProps={{
            endAdornment: (
              <Tooltip title="Minimum 8 characters and must include numbers.">
                <IconButton>
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            ),
          }}
          type="password"
          variant="outlined"
          fullWidth
          margin="normal"
          value={formFields.password}
          onChange={handleChange('password')}
          required
          onFocus={() => handleFocus('password')}
          onBlur={() => handleBlur('password', formFields.password)}
          sx={{ marginLeft: '16px' , marginTop:'20px'  }} // Add left margin 
          
        />

      <Typography sx={{ marginBottom: '-10px', textAlign: 'left', marginLeft: '16px'  }}>
        Confirm Password<span style={{ color: '#E27DDD' }}>*</span>
      </Typography>
      <TextField
          className='customTextFieldSignup'
          placeholder={!focusedField.confirmPassword ? 'Re-enter your password' : ''}
          type="password"
          variant="outlined"
          fullWidth
          margin="normal"
          value={formFields.confirm_password}
          onChange={handleChange('confirm_password')}
          required
          onFocus={() => handleFocus('confirm_password')}
          onBlur={() => handleBlur('confirm_password', formFields.confirm_password)}
          sx={{ marginLeft: '16px', marginTop:'20px' }} // Add left margin 
        />
      </Grid>

      <Button variant="contained" className='loginbutton' fullWidth onClick={handleSignup} sx={{ mt: 2, boxShadow: 'none' }}>
        Sign Up
      </Button>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 3, mb:2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body2" className='text-style'>
              Already have an account?
              </Typography>
              <Typography variant="body2" className="text-style">
              <a href="/" style={{ textDecoration: 'none', color: 'inherit', color: '#E27DDD'  }}>
                &nbsp; Login
              </a>
            </Typography>
            </Box>
            
          </Box>
          {/* Show error message if registration fails */}
            {registrationError && <p style={{ color: 'red' }}>{registrationError}</p>}
      {/* <Button
        variant="text"
        fullWidth
        onClick={() => navigate('/')}
        sx={{ mt: 2 , alignItems:'left'}}
      >
        Already have an account? Log in
      </Button> */}
    </Box>
  </Grid>


      {/* Side Image */}
      <Grid
          item
          xs={12}
          md={5}
          
          container
          justifyContent="center"  // Center horizontally
          alignItems="center"      // Center vertically
          style={{ backgroundColor: '#ffffff', height: '100vh'}}
        >
          <img
            src={sideImage}
            alt="Side"
            style={{
              width: '90%',       // Take full width of the container
              height: 'auto',      // Maintain aspect ratio
              maxWidth: '90vw',   // Restrict maximum width to viewport width
              maxHeight: '90vh',  // Restrict maximum height to viewport height
              objectFit: 'contain' // Adjust to ensure the whole image fits within bounds
            }}
          />
        </Grid>
    </Grid>
  );
};

export default Signup;
