const API_BASE_URL = process.env.REACT_APP_API_URL;

export const fetchNotificationsApi = async (accessToken) => {
    const response = await fetch(`${API_BASE_URL}/api/notification-read-list`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
        },
    });

    if (!response.ok) {
        throw new Error('Failed to fetch notifications');
    }

    return await response.json();
};

export const markNotificationsAsReadApi = async (accessToken, notificationIds) => {
    const response = await fetch(`${API_BASE_URL}/api/notification-bulk-mark-read/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ notification_ids: notificationIds }),
    });

    if (!response.ok) {
        throw new Error('Failed to mark notifications as read');
    }

    return await response.json();
};

export const markNotificationsAsUnreadApi = async (accessToken, notificationIds) => {
    const response = await fetch(`${API_BASE_URL}/api/notification-bulk-mark-unread/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ notification_ids: notificationIds }),
    });

    if (!response.ok) {
        throw new Error('Failed to mark notifications as unread');
    }

    return await response.json();
};

export const deleteNotificationsApi = async (accessToken, notificationIds) => {
    const response = await fetch(`${API_BASE_URL}/api/notification-bulk-delete/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ notification_ids: notificationIds }),
    });

    if (!response.ok) {
        throw new Error('Failed to delete notifications');
    }

    return await response.json();
};