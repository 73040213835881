import React, { useEffect } from 'react';
import styles from './Loader.module.css';

const Loader = () => {
  useEffect(() => {
    const loader = document.querySelector(`.${styles.loader}`);
    const delay = +loader.dataset.delay || 200;
    const dots = loader.querySelectorAll(`.${styles.dot}`);
    dots.forEach((dot, index) => {
      dot.style.animationDelay = `${delay * index}ms`;
    });
  }, []);

  return (
    <div className={styles.loaderContainer}>
      <div className={styles.loader} data-delay="200">
        <div className={styles.dot}></div>
        <div className={styles.dot}></div>
        <div className={styles.dot}></div>
        <div className={styles.dot}></div>
        <div className={styles.dot}></div>
      </div>
    </div>
  );
};

export default Loader;
