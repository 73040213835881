// sagas/authSaga.js


import { call, put, select, takeLatest, debounce} from 'redux-saga/effects';

import {
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGIN_REQUEST,
    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    loginSuccess,
    loginFailure,
    logoutSuccess,
    logoutFailure,
    REAUTHENTICATE_REQUEST,
    REAUTHENTICATE_FAILED,
    REFRESH_TOKEN_REQUEST,
    refreshTokenSuccess,
    refreshTokenFailure,
    REGISTER_REQUEST,
    registerSuccess,
    registerFailure,
    storeTokens,
    clearTokens,
    storeUserDetails,
    RESET_PASSWORD_REQUEST, 
    resetPasswordSuccess, 
    resetPasswordFailure,
    SETNEW_PASSWORD_REQUEST,
    setNewPasswordSuccess,
    setNewPasswordFailure,
    GOOGLE_LOGIN_REQUEST,
    GOOGLE_LOGIN_SUCCESS,
    GOOGLE_LOGIN_FAILURE,
    GOOGLE_LOGIN_CALLBACK,
    googleLoginSuccess, googleLoginFailure, googleLoginCallback,
    LINKEDIN_LOGIN_REQUEST,
    LINKEDIN_LOGIN_CALLBACK,
    linkedInLoginSuccess, linkedInLoginFailure, linkedInLoginCallback,
    MICROSOFT_LOGIN_REQUEST,
    MICROSOFT_LOGIN_CALLBACK,
    MicrosoftLoginFailure, MicrosoftLoginCallback,
    MicrosoftLoginSuccess,
    SOCIAL_MEDIA_LOGIN_REQUEST,
    SOCIAL_MEDIA_LOGIN_CALLBACK,
    socialMediaLoginSuccess, socialMediaLoginFailure, socialMediaLoginCallback,
} from '../actions/authActions';


import { loginApi, refreshTokenApi, sendPasswordLinkRequest, setNewPasswordRequest, 
  socialMediaLoginRequestAPI,socialMediaCallbackAPI} from '../../api/authApi'
import { isTokenValid } from '../../utils/tokenUtils';


// Worker Saga: Handling login API call
function* handleLogin(action) {
  console.log("handleLogin.......... ")
    try {
        const response = yield call(loginApi, action.payload);
        const { access, refresh, user_id, first_name, last_name, email } = response;
        console.log(' response', response);
        // const { accessToken, refreshToken } = response;
        if (response.access && response.refresh) {
            // Store access token and refresh token in localStorage
            // yield put({ type: LOGIN_SUCCESS, payload: { access, refresh } });
            yield put({ type: 'STORE_TOKENS', payload: { accessToken: access, refreshToken: refresh } });
            // yield put(storeTokens(access, refresh));
            console.log(' accessToken accessTokenaccessToken ', access);
            localStorage.setItem('accessToken', access);
            localStorage.setItem('refreshToken', refresh);
     
            // Optionally store user information
            localStorage.setItem('userId', user_id);
            localStorage.setItem('firstName', first_name);
            localStorage.setItem('lastName', last_name);
            localStorage.setItem('email', email);
            const accessToken = yield select((state) => state.auth.accessToken);
            console.log('Login accessToken :',accessToken);
            // Dispatch login success
            yield put(loginSuccess('Login successful!'));
            // Dispatch user details to the Redux store
            yield put(storeUserDetails({ user_id, first_name, last_name, email }));
            // Optionally navigate to the dashboard after login
            // You may need to use history.push('/dashboard') or a similar method
          } else {
            // Dispatch login failure if no tokens are found in the response
            yield put(loginFailure('Login failed. Please try again.'));
          }
        // Dispatch success action
        yield put({ type: LOGIN_SUCCESS, payload: {
          userDetails: {
            first_name: response.first_name,
            last_name: response.last_name,
            email: response.email,
          },
          access, refresh} });
        // yield put(loginSuccess({
        //     accessToken: response.access,
        //     refreshToken: response.refresh,


        // }));
    } catch (error) {
        // yield put(loginFailure(error.message));
        yield put({ type: LOGIN_FAILURE, payload: error.message });
    }
}


// Worker Saga: Handling token refresh API call
function* handleRefreshToken() {
    try {
        const refreshToken = localStorage.getItem('refreshToken');
        if (!refreshToken) throw new Error('No refresh token found');


        const response = yield call(refreshTokenApi, { refreshToken }); // Call refresh API
        const { accessToken } = response;


        // Update access token in localStorage
        localStorage.setItem('accessToken', accessToken);


        // Dispatch success action
        yield put({ type: LOGIN_SUCCESS, payload: { accessToken, refreshToken } });
        // const refreshToken = yield select(state => state.auth.refreshToken);
        // const response = yield call(refreshTokenApi, refreshToken);
       
        // if (response.access) {
        //     yield put(refreshTokenSuccess(response.access));
        // } else {
        //     yield put(refreshTokenFailure());
        // }
    } catch (error) {
        // yield put(refreshTokenFailure());
        yield put({ type: LOGIN_FAILURE, payload: error.message });
    }
}




// API call for registration
function registerApi(userData) {
    console.log("Calling API with:", userData);
    return fetch('https://staging.runmybot.com/api/register/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userData),
    }).then(response => response.json());
  }
 
  // Worker saga
  function* handleRegister(action) {
    console.log("handleRegister... ",action)
    try {
      const response = yield call(registerApi, action.payload);
      console.log('API Response:', response);
      if (response.errors && response.errors[0] && response.errors[0].detail) {
       
        const errorMessage = response.errors[0].detail;
        yield put(registerFailure(errorMessage));  // Dispatch failure with the specific error message
      } else if (response.message) {
        yield put(registerSuccess('Registration successful!'));
      } else {
        yield put(registerFailure('Registration failed. Please try again.'));
      }
    } catch (error) {
      yield put(registerFailure('An error occurred during registration.'));
    }
  }
 
  // Watcher saga
  export function* watchRegister() {
    console.log('watchRegister is watching REGISTER_REQUEST...');
    yield takeLatest(REGISTER_REQUEST, handleRegister);
  }
// Watcher saga for refresh token
  export function* watchTokenExpiration() {
    console.log('watchRegister is watching REFRESH_TOKEN_REQUEST...');
    yield takeLatest(REFRESH_TOKEN_REQUEST, handleRefreshToken);
}


// Watcher Saga: Watches for login and refresh token actions
export default function* authSaga() {
   console.log("authSaga..... ")
    yield takeLatest(LOGIN_REQUEST, handleLogin);
    yield takeLatest(REFRESH_TOKEN_REQUEST, handleRefreshToken);
    // yield takeLatest(REAUTHENTICATE_REQUEST, handleReAuthenticate);
}


// Worker saga: handles the logout process
function* handleLogout() {
  try {
    // You can also clear tokens from localStorage or cookies if you're storing them there
    // yield call(logoutRequestAPI); // Call API if needed
    yield put(clearTokens()); // Dispatch action to clear tokens from the store
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('userId');
    localStorage.removeItem('firstName');
    localStorage.removeItem('lastName');
    // Optionally, you can call an API to log the user out on the server if necessary
    // yield call(apiLogout);
    yield put(logoutSuccess());
    // Redirect to login page
    // yield put(push('/login')); // Redirect to login page using react-router


  } catch (error) {
    console.error('Logout failed:', error);
  }
}
// Watcher saga: listens for LOGOUT action
export function* watchLogoutSaga() {
  yield takeLatest(LOGOUT_REQUEST, handleLogout);
}

export function* watchLogin() {
  console.log("watchLogin..... ")
  yield takeLatest(LOGIN_REQUEST, handleLogin);
}

// Saga to check if the token is valid on app load
export function* handleReAuthenticate(action) {
  console.log("Re-authenticate saga triggered action.payload: " , action.payload);
  // try {
    const token = localStorage.getItem('accessToken');
    
    console.log("TOEKN ", token)
    if (token) {
      const refresh = localStorage.getItem('refreshToken');
      const userid = localStorage.getItem('userId');
      const first_name = localStorage.getItem('firstName');
      const last_name  = localStorage.getItem('lastName');
      const email  = localStorage.getItem('email');
      yield put(storeUserDetails({ userid, first_name, last_name, email }));
      yield put({ type: 'STORE_TOKENS', payload: { accessToken: token, refreshToken: refresh } });
      // Validate the token (you can also refresh it if needed)
      const isValid = yield call(isTokenValid, token);
      if (isValid) {
        // Token is valid, you can update the state
        yield put({ type: 'REAUTHENTICATE_SUCCESS', payload: token });
      } else {
        // Try refreshing the token
        const newToken = yield call(refreshTokenApi, token);
        if (newToken) {
          // Save the new token
          localStorage.setItem('authToken', newToken);
          yield put({ type: 'REAUTHENTICATE_SUCCESS', payload: newToken });
        } else {
          // No valid token, log out the user
          yield put({ type: 'REAUTHENTICATE_FAILED' });
        }
      }
    }
  // }  catch (error) {
  //   console.error('Error during re-authentication:', error);
  //   yield put({ type: LOGIN_FAILURE });
  // }
}

// Watcher saga for re-authentication on app initialization
export function* watchReAuthenticate() {
  console.log('watchReAuthenticate ***** REAUTHENTICATE_REQUEST ')
  yield takeLatest(REAUTHENTICATE_REQUEST, handleReAuthenticate);
}
// Worker saga
function* resetPasswordSaga(action) {
  try {
    const response = yield call(sendPasswordLinkRequest, action.payload.email);
    yield put(resetPasswordSuccess(response.message)); // on success
  } catch (error) {
    yield put(resetPasswordFailure(error.message)); // handle error
  }
}

// Watcher saga
export function* watchResetPassword() {
  yield takeLatest(RESET_PASSWORD_REQUEST, resetPasswordSaga);
}


// Saga worker function
function* handleSetNewPassword(action) {
   try {
    const { userId, token, Password } = action.payload;
    const response = yield call(setNewPasswordRequest, action.payload);
    
    yield put(setNewPasswordSuccess(response.message || 'Password reset successful!'));
      
    }
     catch (error) {
       yield put(setNewPasswordFailure(error.message || 'Password reset failed.'))
    }
}

// Watcher saga
export function* watchSetNewPassword() {
  yield takeLatest(SETNEW_PASSWORD_REQUEST, handleSetNewPassword);
}





// Worker Saga for Google Login
function* handleSocialMediaLogin({ payload }) {
  try {
   
    const userData = yield call(socialMediaLoginRequestAPI, payload);

    const updatedUserData = {
      ...userData,
      ...payload
    };
    // Check if `login_url` matches known provider login URLs
    if (
      typeof updatedUserData.login_url === 'string' && 
      /(https:\/\/accounts\.google\.com|https:\/\/www\.linkedin\.com|https:\/\/login\.microsoftonline\.com)/.test(updatedUserData.login_url)
    ) {
      // Redirect to the provider's authorization URL
      window.location.href = updatedUserData.login_url;
      return; // Stop execution as we're redirecting
    }

    // Handle success in case no redirect is needed
    yield put(socialMediaLoginSuccess(updatedUserData));
    // if (typeof updatedUserData.login_url === 'string' && (userData.login_url.startsWith('https://accounts.google.com') || userData.login_url.startsWith('https://www.linkedin.com') || userData.login_url.startsWith('https://login.microsoftonline.com'))) {
    //   window.location.href = updatedUserData.login_url; // Redirect to authorization URL
      
    //   yield put(socialMediaLoginCallback(updatedUserData)); 
    //   return;
    // }

    // yield put(socialMediaLoginSuccess(updatedUserData)); // Dispatch success action with user data
  } catch (error) {
     yield put(socialMediaLoginFailure(error.message)); // Dispatch failure action with error
  }
}


function* handleSocialMediaLoginCallback({ payload }) {
  try {
    // Call API to exchange code for user data
    const { provider, currentUrl , code } = payload
    const userData = yield call(socialMediaCallbackAPI, payload);
    const { access, refresh, user_id, first_name, last_name, email } = userData;
    // Store tokens in localStorage (optional)
    if (userData.access && userData.refresh) {
      yield put({ type: 'STORE_TOKENS', payload: { accessToken: userData.access, refreshToken:userData.refresh } });
      localStorage.setItem('access_token', userData.access);
      localStorage.setItem('refresh_token', userData.refresh);
      yield put(storeUserDetails({ user_id, first_name, last_name, email }));
    }
    yield put(socialMediaLoginSuccess(userData));
  } catch (error) {
     yield put(socialMediaLoginFailure(error.message));
  }
}

// Watcher Saga
export function* watchSocialMediaLogin() {
  yield takeLatest(SOCIAL_MEDIA_LOGIN_REQUEST, handleSocialMediaLogin);
  yield debounce(500, SOCIAL_MEDIA_LOGIN_CALLBACK, handleSocialMediaLoginCallback);
}
