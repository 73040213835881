import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Sidebar from '../components/Sidebar';
import Topbar from '../components/Navbar';
import { Box } from '@mui/material';
// import { collapseSidebar, expandSidebar } from '../actions/sidebarActions';

const DashboardLayout = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  
  const dispatch = useDispatch();
  // Toggle sidebar visibility
  const toggleSidebar = () => {
    setSidebarHidden(!isSidebarHidden);
  };
  const location = useLocation();
  // Define routes where the sidebar should not be shown (e.g., login)
  const noSidebarRoutes = ['/login'];
  // Define pages where the sidebar should be hidden
  const pagesWithHiddenSidebar = ['/login','/signup', '/integrations', '/workflow-library','/workflow-builder','/exp-runmybot','/event-pro'];
  // Check if the current page should hide the sidebar
  let cleanedPath = location.pathname.replace(/\/\d+$/, '');  
  const isPageWithHiddenSidebar = pagesWithHiddenSidebar.includes(cleanedPath);

  // State to control the sidebar visibility
  const [isSidebarHidden, setSidebarHidden] = useState(false);
  useEffect(() => {
    // Update the sidebar visibility based on the current page
    setSidebarHidden(isPageWithHiddenSidebar);
  }, [location.pathname, isPageWithHiddenSidebar]);


  return (
    <Box sx={{ display: 'flex' }}>
    {/* <div className="dashboard-layout"> */}
      {/* Topbar */}
      

      {/* Sidebar */}
      {/* {isSidebarOpen && <Sidebar />} */}
      {/* <Sidebar isHidden={!isSidebarOpen} /> */}
      <Sidebar isHidden={isSidebarHidden} toggleSidebar={toggleSidebar} />
      <Topbar toggleSidebar={toggleSidebar} />
      {/* Main content area */}
      {/* Content will shift left when sidebar is hidden */}
      <Box
        className="content"
        sx={{
          width: '100%',
          marginLeft: isSidebarHidden ? '0' : '250px', // Apply dynamic margin based on sidebar visibility
          transition: 'margin-left 0.3s ease', // Optional: Add smooth transition effect
        }}
      >
        <Outlet />
      </Box>
      
      </Box>
    
  );
};

export default DashboardLayout;
