import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Search, Trash2, CheckSquare, Square, Mail, MailOpen } from "lucide-react";
import styles from "./NotificationsSidebarHeader.module.css";
import { ReactComponent as SearchIcon } from "../../../../assetts/images/searchIcon.svg";

const NotificationsSidebarHeader = ({
  notifications,
  selectedNotifications,
  onSelectAll,
  onBulkDelete,
  onBulkMarkAsRead,
  onBulkMarkAsUnread,
  searchTerm,
  setSearchTerm,
  totalResults
}) => {
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const hasSelectedItems = selectedNotifications.length > 0;

  useEffect(() => {
    const handler = setTimeout(() => {
      setSearchTerm(debouncedSearchTerm);
    }, 300);
    return () => clearTimeout(handler);
  }, [debouncedSearchTerm, setSearchTerm]);

  return (
    <motion.div 
      className={styles.actionsHeader}
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
     
      <div className={styles.searchContainer}>
        <SearchIcon className={styles.searchIcon} />
        <input
          type="text"
          placeholder="Search notifications..."
          className={styles.searchInput}
          value={debouncedSearchTerm}
          onChange={(e) => setDebouncedSearchTerm(e.target.value)}
        />
        {totalResults > 0 && (
          <span className={styles.resultCount}>
            {totalResults} result{totalResults !== 1 ? 's' : ''}
          </span>
        )}
      </div>

      <motion.div 
        className={styles.bulkActions}
        animate={{ 
          opacity: notifications.length ? 1 : 0.5,
          scale: notifications.length ? 1 : 0.98 
        }}
      >
       

        <motion.div 
          className={`${styles.actions} ${hasSelectedItems ? styles.active : ''}`}
          animate={{ opacity: hasSelectedItems ? 1 : 0.5 }}
        >
           <motion.label
          className={styles.selectAllLabel}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          <motion.div 
            className={styles.checkbox}
            onClick={onSelectAll}
          >
            {selectedNotifications.length === notifications.length ? (
              <CheckSquare size={18} className={styles.checkIcon} />
            ) : (
              <Square size={18} className={styles.checkIcon} />
            )}
          </motion.div>
        </motion.label>
          <motion.button
            className={styles.actionButton}
            onClick={onBulkMarkAsRead}
            disabled={!hasSelectedItems}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <Mail size={16} />
            <span>Read</span>
          </motion.button>

          <motion.button
            className={styles.actionButton}
            onClick={onBulkMarkAsUnread}
            disabled={!hasSelectedItems}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <MailOpen size={16} />
            <span>Unread</span>
          </motion.button>

          <motion.button
            className={`${styles.actionButton} ${styles.deleteButton}`}
            onClick={onBulkDelete}
            disabled={!hasSelectedItems}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <Trash2 size={16} />
            <span>Delete</span>
          </motion.button>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default NotificationsSidebarHeader;