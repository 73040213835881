import React from 'react';
import styles from './CheckboxField.module.css';

const CheckboxField = ({ field, value, onChange, error, style = {} }) => {
    const handleChange = (e) => {
        const { checked, value: checkboxValue } = e.target;

        if (field.options) {
            const updatedValues = checked
                ? [...(value || []), checkboxValue]
                : (value || []).filter((val) => val !== checkboxValue);

            onChange(updatedValues);
        } else {
            onChange(checked);
        }
    };

    return (
        <div className={styles.fieldContainer} style={style.container}>
            <label className={styles.label} style={style.label}>
                {field.label}
            </label>
            <div className={styles.checkboxGroup}>
                {field.options ? (
                    field.options.map((option) => (
                        <label key={option.value} className={styles.checkboxLabel}>
                            <input
                                type="checkbox"
                                value={option.value}
                                checked={(value || []).includes(option.value)}
                                onChange={handleChange}
                                className={styles.checkbox}
                                style={style.checkbox}
                            />
                            {option.label}
                        </label>
                    ))
                ) : (
                    <label className={styles.checkboxLabel}>
                        <input
                            type="checkbox"
                            id={field.id}
                            checked={value || field.defaultChecked || false}
                            onChange={handleChange}
                            className={styles.checkbox}
                            style={style.checkbox}
                        />
                        {field.label}
                    </label>
                )}
            </div>
            {error && <p className={styles.error}>{error}</p>}
        </div>
    );
};

export default CheckboxField;
