import React from 'react';
import styles from './ButtonRenderer.module.css';

const ButtonRenderer = ({ button, onClick }) => {
    const handleClick = () => {
        if (onClick) {
            onClick(button);
        }
    };

    return (
        <button
            type={button.type === 'submit' ? 'submit' : 'button'}
            className={`${styles.button} ${styles.primary}` }
            onClick={handleClick}
        >
            {button.label}
        </button>
    );
};

export default ButtonRenderer;
