// Helper function to capitalize each word in the label
export const capitalizeWords = (label) => {
    return label.replace(/-/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
};

// Function to generate breadcrumb elements, excluding numeric IDs
export const generateBreadcrumbElements = (breadcrumbs, navigate) => {
    return breadcrumbs
        .filter(crumb => isNaN(crumb.label))
        .map((crumb, index, array) => {
            const isLastBreadcrumb = index === array.length - 1;

            if (isLastBreadcrumb) {
                return (
                    <span key={index}>
                        {capitalizeWords(crumb.label)}
                    </span>
                );
            }

            return (
                <span
                    key={index}
                    onClick={() => navigate(crumb.path)}
                    style={{ cursor: 'pointer' }}
                >
                    {capitalizeWords(crumb.label)}
                    {' > '}
                </span>
            );
        });
};
