import React from 'react';

const Logs = () => {
  return (
    <div style={{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center', 
      justifyContent: 'center', 
      height: '100vh' // Makes the div full height of the viewport
    }}>
      <h1>Logs</h1>
      <p>This is the Logs content area.</p>
    </div>
  );
};

export default Logs;
