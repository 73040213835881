import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import styles from "./WorkflowTile.module.css";
import infoPink from "../../../../assetts/images/infoPink.svg";
import playButton from "../../../../assetts/images/playButton01.svg";
import DialogBox from "../../../../components/reusableComponents/DialogBox/DialogBox";

const WorkflowTile = ({ id, icon, title, category, info }) => {
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);

  const handleNavigate = (url, state = {}) => {
    if (id) {
      navigate(url, { state });
    } else {
      console.warn("No ID provided for navigation.");
    }
  };

  const handleClose = () => {
    setShowDialog(false);
  };

  return (
    <div
      className={styles.tile}
      style={{ cursor: "pointer" }}
      role="button"
      >
      <div className={styles.tileContent}>
        <div className={styles.iconContainer}>
          <img src={icon} alt="icon" className={styles.tileIcon} />
        </div>
        <div className={styles.title} title={title}>
          {title}
        </div>
      </div>
      <div className={styles.footer}>
      <div className={styles.footerSection1} >
        <Tooltip title={category} arrow>
          <span className={styles.category}>{category}</span>
        </Tooltip>
        </div>
        <div className={styles.footerSection2} onClick={() =>
              handleNavigate(`/workflow-builder/${id}`, {
                type: "basicWorkflow",
              })
            }>
          <img
            src={playButton}
            alt="Settings"
            className={styles.actionIcon}
            
            aria-label="Go to workflow settings"
          />
        </div>
      </div>
    </div>
  );
};

export default WorkflowTile;
