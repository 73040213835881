// src/components/SearchBox.js
import React from 'react';
import { TextField, InputAdornment, IconButton, Box, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const SearchBox = ({ searchTerm, setSearchTerm, onSearch }) => {
  return (
    <TextField
      variant="outlined"
      size="small"
      placeholder="Search..."
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      sx={{
        color:'#000000',
        width: '180px', // Set the width to 180px
        '& .MuiOutlinedInput-root': {
          borderRadius: '16px',
        },
        // Set the style for the placeholder color
        '& input::placeholder': {
          color: '#A7A7A7', // Adjust this color as needed
          opacity: 1, // Make sure the opacity is set to 1
        },
        // Ensure input color is black
        '& input': {
            color: '#000000',
          },
      }}
    />
  );
};
const SearchComponent = ({ searchTerm, setSearchTerm }) => {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 3, // 72px top margin
          mb: 3,
        }}
      >
        
        {/* Add SearchBox on the right side */}
        <SearchBox searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      </Box>
    );
  };
  
  export default SearchComponent;