import React, { useState } from 'react';
import TextField from './IndividualFieldComponents/TextField/TextField';
import SelectField from './IndividualFieldComponents/SelectField/SelectField';
import CheckboxField from './IndividualFieldComponents/CheckboxField/CheckboxField';
import RadioField from './IndividualFieldComponents/RadioField/RadioField';
import TextareaField from './IndividualFieldComponents/TextareaField/TextareaField';
import DateField from './IndividualFieldComponents/DateField/DateField';
import FileUploadField from './IndividualFieldComponents/FileUploadField/FileUploadField';
import NumberField from './IndividualFieldComponents/NumberField/NumberField';
import DateTimeField from './IndividualFieldComponents/DateTimeField/DateTimeField';
import AutocompleteField from './IndividualFieldComponents/AutocompleteField/AutocompleteField';
import DynamicPopup from '../DynamicPopup/DynamicPopup';
import styles from './FieldRenderer.module.css';

const FieldRenderer = ({ field, value, onChange, error }) => {
    const [showPopup, setShowPopup] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState(value || []);

    const handleFileSelect = (files) => {
        setUploadedFiles(files);  // Store the file data
        onChange(files);          // Update form state immediately with files
        setShowPopup(false);     // Close popup after selection
    };

    const handleFileClick = () => {
        setShowPopup(true);  // Open the popup to modify or add more files
    };

    const renderFieldByType = () => {
        switch (field.type) {
            case 'text':
            case 'password':
            case 'email':
                return <TextField field={field} value={value} onChange={onChange} error={error} />;
            case 'select':
                return <SelectField field={field} value={value} onChange={onChange} error={error} />;
            case 'checkbox':
                return <CheckboxField field={field} value={value} onChange={onChange} error={error} />;
            case 'radio':
                return <RadioField field={field} value={value} onChange={onChange} error={error} />;
            case 'textarea':
                return <TextareaField field={field} value={value} onChange={onChange} error={error} />;
            case 'date':
                return <DateField field={field} value={value} onChange={onChange} error={error} />;
            case 'file':
                return (
                    <div className={styles.fieldContainer}>
                        <label className={styles.fieldLabel}>{field.label}</label>
                        <div className={styles.uploadField} onClick={handleFileClick}>
                            {uploadedFiles.length > 0 ? uploadedFiles.map((file, index) => (
                                <div key={index} className={styles.fileName}>{file.name}</div>
                            )) : "Click here to upload files."}
                        </div>
                        {showPopup && (
                            <DynamicPopup title="Upload File" onClose={() => setShowPopup(false)}>
                                <FileUploadField field={field} existingFiles={uploadedFiles} onChange={handleFileSelect} error={error} />
                            </DynamicPopup>
                        )}
                    </div>
                );
            case 'number':
                return <NumberField field={field} value={value} onChange={onChange} error={error} />;
            case 'datetime-local':
                return <DateTimeField field={field} value={value} onChange={onChange} error={error} />;
            case 'autocomplete':
                return <AutocompleteField field={field} value={value} onChange={onChange} error={error} />;
            default:
                return <div>Unsupported field type: {field.type}</div>;
        }
    };

    return renderFieldByType();
};

export default FieldRenderer;
