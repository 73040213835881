import React, { useEffect, useMemo } from 'react';
import Uppy from '@uppy/core';
import { Dashboard } from '@uppy/react';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import styles from './FileUploadField.module.css';

const FileUploadField = ({ field, existingFiles, onChange, error, style = {} }) => {
    const uppy = useMemo(() => {
        const uppyInstance = new Uppy({
            autoProceed: false,
            restrictions: {
                maxNumberOfFiles: field.validation?.maxNumberOfFiles || 5,
                allowedFileTypes: field.accept ? [field.accept] : null,
            }
        });

        // Handle complete event
        uppyInstance.on('complete', (result) => {
            const newFiles = result.successful.map(file => ({
                name: file.name,
                type: file.type,
                data: file.data,
            }));
            onChange(newFiles); // Update form state
        });

        return uppyInstance;
    }, [field.validation?.maxNumberOfFiles, field.accept, onChange]);

    useEffect(() => {
        // This function adds files to Uppy's instance when the component mounts
        if (existingFiles && existingFiles.length > 0) {
            existingFiles.forEach(file => {
                try {
                    uppy.addFile({
                        name: file.name, // Assumes you have the file name
                        type: file.type, // Assumes you have the file type
                        data: file.data, // You must have a File object here
                        source: 'Local', // This can be your own source identifier
                        isRemote: false // Set to true if the file is remote
                    });
                } catch (err) {
                    console.error('Error adding files:', err);
                }
            });
        }

    }, [uppy, existingFiles]);

    return (
        <div className={styles.fieldContainer} style={style.container}>
            <label className={styles.label} style={style.label}>
                {field.label}
            </label>
            <Dashboard
                uppy={uppy}
                proudlyDisplayPoweredByUppy={false}
                height={470}
                hideUploadButton={false}
            />
            {error && <p className={styles.error}>{error}</p>}
        </div>
    );
};

export default FileUploadField;
