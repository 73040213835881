import React, { forwardRef } from 'react';
import MaskedInput from 'react-text-mask';

// Use forwardRef to allow refs to pass through the component
const PhoneMask = forwardRef(function PhoneMask(props, ref) {
  const { onChange, ...other } = props;
  
  return (
    <MaskedInput
      {...other}
      ref={ref} // pass ref correctly
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]} // US phone format
      placeholderChar={'\u2000'}
      showMask
      onChange={(event) => {
        onChange({
          target: {
            name: props.name,
            value: event.target.value,
          },
        });
      }} // Ensure the onChange is forwarded correctly
    />
  );
});

export default PhoneMask;
