import React, { useRef, useCallback, useEffect } from "react";
import WorkflowTile from "../../components/WorkflowTile/WorkflowTile";
import styles from "./WorkflowGrid.module.css";

const WorkflowGrid = ({ tiles, hasMore, onLoadMore, loading, error }) => {
  const loaderRef = useRef(null);
  const loadingRef = useRef(false);
  const wrapperRef = useRef(null);

  const handleLoadMore = useCallback(() => {
    if (!loadingRef.current && hasMore) {
      loadingRef.current = true;
      onLoadMore().finally(() => {
        loadingRef.current = false;
      });
    }
  }, [hasMore, onLoadMore]);

  const handleObserver = useCallback(
    (entries) => {
      const target = entries[0];
      if (target.isIntersecting) {
        handleLoadMore();
      }
    },
    [handleLoadMore]
  );

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: "100px",
      threshold: 0.1,
    });

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [handleObserver]);

  if (loading && tiles.length === 0) {
    return <div>Loading workflows...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className={styles.wrapper} ref={wrapperRef}>
      <div className={styles.gridContainer}>
        {tiles.map((tile) => (
          <WorkflowTile
            key={tile.id}
            id={tile.id}
            icon={tile?.icon || null}
            title={tile.name}
            category={tile.functions.join("/")}
            info={tile.info}
          />
        ))}
      </div>

      {hasMore && (
        <div ref={loaderRef} className={styles.loaderContainer}>
          <div className={`${styles.arrow} ${loadingRef.current ? styles.loading : ''}`} />
          <span className={styles.loaderText}>
            {loadingRef.current ? 'Loading...' : 'Scroll for more'}
          </span>
        </div>
      )}
    </div>
  );
};

export default WorkflowGrid;